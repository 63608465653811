import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
  padding: 4rem 0 2rem;
  @media(min-width: 600px) {
    padding: 6rem 0;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: calc(100vw * (578 / 1920));
  position: relative;
  display: flex;
  div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  img {
    object-fit: cover;
  }
  margin: 0 0 3rem;
`;

export const HeaderWrapper = styled.span`
  max-width: 1350px;
  width: 100%;
  padding: 0 1rem;
  z-index: 1;
  text-align: center;
  margin: auto;
  @media (min-width: 500px) {
    text-align: left;
    height: fit-content;
    margin: auto auto 2rem;
  }
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  display: flex;
`;

export const Header = styled.h2`
  font-size: 2rem;
  @media (min-width: 700px) {
    font-size: 3rem;
  }
  @media (min-width: 800px) {
    font-size: 4rem;
  }
  font-family: Poppins;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  text-shadow: 0 10px 50px #fff;
`;

export const BodyWrapper = styled.div`
  max-width: 1350px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

export const IconWrapper1 = styled.div`
  min-width: 3rem;
  width: 3rem;
  min-height: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eb58b9;
  margin: .5rem auto 1rem;
  @media(min-width: 600px) {
    margin: auto 0.75rem auto 0;
  }
`;

export const IconWrapper2 = styled(IconWrapper1)`
  background-color: #f96969;
`;

export const IconWrapper3 = styled(IconWrapper1)`
  background-color: #ff9f14;
`;

export const IconWrapper = styled(IconWrapper1)`
  background-color: #797ef6;
`;

export const TillWrapper = styled.article<{ isWide?: boolean }>`
  border-radius: 8px;
  display: flex;
  padding: 1.5rem 2rem;
  margin: 1rem auto;
  background-color: #fff;
  width: 100%;
  align-items: center;
  box-shadow: 5px 5px 15px #bdbdbd;
  flex-direction: column;
  @media(min-width: 600px) {
    flex-direction: row;
  }
`;

export const Icon = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1rem;
  line-height: 160%;
`;

export const Red = styled.span`
  font-weight: 600;
  color: #b70909;
`;
