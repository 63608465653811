import styled from 'styled-components';

export const IconWrapper = styled.div`
  min-width: 2rem;
  height: 2rem;
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f96969;
  margin: auto 0.75rem auto 0;
`;

export const TillWrapper = styled.article<{ isWide?: boolean }>`
  border-radius: 4px;
  display: flex;
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: ${({ theme: { mainBackground } }) => mainBackground};
  background-color: #fff;
  :hover {
    filter: invert(1);
  }
  transition: filter 250ms;
  margin: 1rem auto;
  max-width: 800px;
  width: 100%;
  align-items: center;
  box-shadow: 5px 5px 15px ${({ theme: { mainBlue } }) => mainBlue}55;
`;

export const Label = styled.label`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1.125rem;
  line-height: 160%;
  cursor: pointer;
  margin: auto;
  font-weight: 500;
`;

export const Div800 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 4px 0 1px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  background-color: #fff;
  border-radius: 8px 8px 0 0;
`;

export const LabelBottom = styled.img`
  flex-grow: 1;
  margin-right: 4px;
`;

export const HeaderH3 = styled.h3`
  font-family: Poppins;
  line-height: 48px;
  ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 0 2rem;
  font-size: 2rem;
  @media(min-width: 550px) {
    font-size: 2.5rem;
  }
  @media(min-width: 950px) {
    font-size: 3rem;
  }
`;

export const LinksWrapper = styled.div`
  width: 100%;
  padding: 0 2rem;
  @media(min-width: 550px) {
    min-width: 424px;
    padding: 0 3rem;
  }

`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  top: -6rem;
  position: relative;
  @media(min-width: 550px) {
    top: -8rem;
  }
  @media(min-width: 950px) {
    top: -10rem;
  }
`

export const Photo = styled.div`
  flex-grow: 1;
  display: none;
  margin: auto 3rem auto 0;
  @media(min-width: 800px) {
    display: inline-block;
  }
`

export const Image = styled.img`
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
