import { IUserLocation } from "../redux/data/types";

export const getFormattedInitialLocations = (initialLocations: IUserLocation[] = []) => {
    if (initialLocations.length === 0) {
        return [];
    }

    return initialLocations.map((initialLocation: IUserLocation) => {
        const { location: { name, secondaryName } } = initialLocation
        return `${name} ${secondaryName}`
    })
}

export const getFormattedUserLocations = (locs: IUserLocation[]) => locs ? locs.map((loc: IUserLocation) => loc.location) : []