import * as A from './actionTypes'
import * as api from '../../api/userAPI'
import { IUpdatePayload, IUser } from './types'
import { history } from '../../App'

export const setUserData = (data: IUser) => ({
  type: A.SET_USER_DATA,
  data,
})

export const setSignInErrorMessage = (message: string) => ({
  type: A.SET_SIGN_IN_ERROR_MESSAGE,
  data: message,
})

export const setIsLoadingData = () => ({
  type: A.SET_LOADING_DATA,
})

export const setIsLoadingDataSuccess = () => ({
  type: A.SET_LOADING_DATA_SUCCESS,
})

export function loginLocally(data: { email: string; password: string }) {
  return function (dispatch: any) {
    dispatch({ type: A.LOGIN_USER })
    return api.loginLocally(data).then(
      (response: any) => {
        dispatch({ type: A.LOGIN_USER_SUCCESS, data: response })
        history.push('/dashboard')
      },
      (error: any) => {
        if (error.response.status === 401) {
          dispatch({ type: A.LOGIN_USER_ERROR, data: error.response.data })
        }
        console.log('An error occurred.', error)
      },
    )
  }
}

export function updateUser(data: IUpdatePayload) {
  return function (dispatch: any) {
    dispatch({ type: A.UPDATE_USER })
    return api.updateUser(data).then(
      (response: any) => {
        dispatch({ type: A.UPDATE_USER_SUCCESS, data: response })
      },
      (error: any) => {
        if (error.response.status === 401) {
          dispatch({ type: A.UPDATE_USER_ERROR, data: error.response.data })
        }
        console.log('An error occurred.', error)
      },
    )
  }
}

export function logout() {
  return async function (dispatch: any) {
    return api.logout().then(
      (response: any) => {
        history.push('/')
        dispatch({ type: A.LOGOUT_USER_SUCCESS, data: response })
      },
      (error: any) => console.log('An error occurred.', error),
    )
  }
}
