import ncbir from '../../../public/partners/ncbir.svg'
import pl from '../../../public/partners/pl.svg'
import pvf from '../../../public/partners/pvf.svg'
import unionFounds from '../../../public/partners/unionFounds.svg'
import union from '../../../public/partners/union.svg'
import useMediaQuery from '../../../lib/hooks/useMediaQuery'
import * as S from './Footer.styles'

export default function Footer() {
  const isDesktop = useMediaQuery('(min-width: 1024px')

  const links = () => (
    <S.NarrowColumn>
      <S.Header>Linki</S.Header>
      <S.Spacer />
      <S.StyledLink to={'/research-and-development'}>Projekt R+B</S.StyledLink>
      <S.StyledLink to={'/tdm'}>TDM</S.StyledLink>
      <S.StyledLink to={'/about-us'}>Kim jesteśmy</S.StyledLink>
      <S.StyledLink to={'/laboratory'}>Laboratorium</S.StyledLink>
      <S.StyledLink to={'/advantages'}>Zalety usługi</S.StyledLink>
      <S.StyledLink to={'/dashboard'}>Strefa lekarza</S.StyledLink>
    </S.NarrowColumn>
  )

  const terms = () => (
    <S.NarrowColumn>
      {/* <S.Header>Prawa serwisu</S.Header>
      <S.Spacer />
      <S.StyledLink to={'/'}>TOS</S.StyledLink>
      <S.StyledLink to={'/'}>Cookies</S.StyledLink> */}
    </S.NarrowColumn>
  )

  return (
    <S.Wrapper>
      <S.Body>
        <S.WideColumn>
          <S.Header>O nas</S.Header>
          <S.Spacer />
          <S.Description>
            Sąd Rejonowy w Rzeszowie,
            <br />
            XII Wydział Gospodarczy Krajowego Rejestru Sądowego.
            <br />
            Kapitał zakładowy spółki 14&nbsp;000,00 PLN.
            <br />
            <br />
            NIP: 5170405810
            <br />
            REGON: 385772655
            <br />
            KRS: 0000837142
            <br />
          </S.Description>
          {/* <S.SubHeader>Śledź nas na:</S.SubHeader>
          <S.MediaWrapper>
            <Link to={'/'}>
              <S.Twitter />
            </Link>
            <Link to={'/'}>
              <S.Facebook />
            </Link>
            <Link to={'/'}>
              <S.StyledLinkedIn />
            </Link>
            <Link to={'/'}>
              <S.Pinterest />
            </Link>
          </S.MediaWrapper> */}
        </S.WideColumn>
        {isDesktop ? links() : null}
        {isDesktop ? terms() : null}
        <S.WideColumn>
          <S.Header>Kontakt</S.Header>
          <S.Spacer />
          <S.ContactItem href={'tel:+48 505 543 170'}>
            <S.MobileIcon />
            <S.Label>+48 505 543 170</S.Label>
          </S.ContactItem>
          <S.ContactItem href={'mailto:biuro@monitlab.pl'}>
            <S.MailIcon />
            <S.Label>biuro@monitlab.pl</S.Label>
          </S.ContactItem>
          <S.ContactAddress>
            <S.MapPin />
            <S.Label>
              MonitLab Sp. z o.o. <br />
              Pl. Kilińskiego 2, 35-005 Rzeszów
            </S.Label>
          </S.ContactAddress>
        </S.WideColumn>
      </S.Body>
      {!isDesktop && (
        <S.Body>
          {links()}
          {terms()}
        </S.Body>
      )}
      <S.Body>
        <S.WideColumn>
          <S.Spacer />
          <S.Description>&copy; Copyright Monitlab 2021</S.Description>
        </S.WideColumn>
      </S.Body>
      <S.PartnersWrapper>
        <S.PartnerIcon>
          <img src={ncbir} alt={'ncbir logo'} />
        </S.PartnerIcon>
        <S.PartnerIcon>
          <img src={pl} alt={'pl logo'} />
        </S.PartnerIcon>
        <S.PartnerIcon>
          <img src={pvf} alt={'pvf logo'} />
        </S.PartnerIcon>
        <S.PartnerIcon>
          <img src={unionFounds} alt={'EE UU founds logo'} />
        </S.PartnerIcon>
        <S.PartnerIcon>
          <img src={union} alt={'EE UU logo'} />
        </S.PartnerIcon>
      </S.PartnersWrapper>
    </S.Wrapper>
  )
}
