import WhyImportant from '../components/WhyImportant/WhyImportant'
import ExplanationSection from '../components/ExplanationSection/ExplanationSection'
import NewsSection from '../components/NewsSection/NewsSection'
import ContactSection from '../components/ContactSection/ContactSection'
import PictureAndListSection from '../components/PictureAndListSection/PictureAndListSection'
import HeaderSection from '../components/HeaderSection/HeaderSection'

export default function IndexPage() {
  return (
    <>
      <HeaderSection
        header={'PROJEKT B+R'}
        description={
          'Od stycznia 2020 roku prowadzimy projekt badawczo-rozwojowy, którego celem jest opracowanie metody HPLC oznaczania poziomu pozakonazolu w osoczu ludzkim. Zastosowanie metody w Terapeutycznym Monitorowaniu Leków (TDM) pozwoli podnieść skuteczność i bezpieczeństwo terapii oraz profilaktyki grzybic układowych.'
        }
      />
      <WhyImportant />
      <ExplanationSection />
      <PictureAndListSection
        title={'Oferta'}
        listItems={[
          `Laboratorium MONITLAB oferuje usługę TDM pozakonazolu w\u00A0osoczu. Do oznaczenia niezbędna jest bardzo niewielka ilość matrycy, co jest niezwykle istotne w\u00A0rutynowym monitorowaniu stężeń leku.`,
          `Oferta obejmuje zarówno oznaczenie pojedynczych próbek (tzw stężeń minimalnych), jak również pełnych profili farmakokinetycznych, pozwalających na wykrycie potencjalnych interakcji z\u00A0innymi stosowanymi jednocześnie lekami.`,
          `Szczegóły oferty ustalane są indywidualnie poprzez formularz kontaktowy bądź kontakt telefoniczny lub mailowy.`,
        ]}
      />
      <NewsSection />
      <ContactSection />
    </>
  )
}
