import styled from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
`

export const Background = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vw * (578 / 1920));
`

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const Veil = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background: ${({ theme: { mainBlue } }) => ` linear-gradient(-90deg, ${mainBlue} 0%, #fff 100%)`};
  position: absolute;
  top: 0;
  left: 0;
`

export const Body = styled.div`
  max-width: 1350px;
  width: 100%;
  padding: 0 1rem;
  z-index: 2;
  margin: 0 auto;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  box-sizing: border-box;
  position: relative;
  top: calc(100vw * (124 / -1920));
`

export const Div800 = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
`

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 4px 0 1px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  background-color: #fff;
  border-radius: 8px 8px 0 0;
`

export const LabelBottom = styled.img`
  flex-grow: 1;
  margin-right: 4px;
`

export const Header = styled.h1`
  font-family: Poppins;
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 0 2rem;
`

export const Description = styled.p`
  font-family: Poppins;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-size: 18px;
  line-height: 30px;
  a {
    text-decoration: underline;
    color: ${({ theme: { mainBlue } }) => mainBlue};
    :visited {
      color: ${({ theme: { mainBlue } }) => mainBlue};
    }
    :hover {
      text-shadow: 2px 2px 2px ${({ theme: { mainBlue } }) => mainBlue}99;
    }
    transition: 250ms;
  }
`
