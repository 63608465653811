import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  padding: 0 18px 100px;
  font-family: Poppins;
`;

export const MessageBody = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 5rem auto;
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1340px;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
`;

export const TillText = styled.p`
  margin: 0;
  font-weight: 600;
  line-height: 1.5;
  width: 100%;
  font-family: Poppins;
`;

export const TillLink = styled.a`
  margin: 0;
  font-weight: 600;
  line-height: 1.5;
  width: 100%;
  cursor: pointer;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :visited {
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
`;

export const MessageText = styled(TillText)`
  line-height: 2;
`;

export const Ul = styled.ul`
  width: 100%;
  margin: 0;
`;

export const StyledLi = styled.li`
  margin: 0;
  font-weight: 600;
  line-height: 2;
  margin-left: 34px;
`;

export const StyledA = styled.a`
  font-weight: 500;
  text-decoration: underline;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :visited {
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
`;
