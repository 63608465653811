import DotLoader from 'react-spinners/RingLoader'
import Theme from '../../../Theme'
import * as S from './Spinner.styles'

export default function Spinner () {
  return (
    <S.Wrapper>
      <DotLoader size={150} color={Theme.mainRed} loading={true} />
    </S.Wrapper>
  )
}