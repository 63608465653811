import NewLocations from '../components/Dashboard/NewLocations/NewLocations'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getLocation } from '../redux/data/actions'
import { useEffect } from 'react'
import { locationSelector } from '../redux/data/selectors'

export default function LocationsPage() {
  const { locationId } = useParams() as { locationId: string }
  const { location } = useSelector(locationSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLocation(locationId))
  }, [dispatch, locationId])
  return <NewLocations data={location} />
}
