import HeaderSection from '../HeaderSection/HeaderSection';
import Lab from '../../public/rework/lab.jpeg';
import randomLabItem1 from '../../public/assets/randomLabItem1.png';
import randomLabItem2 from '../../public/assets/randomLabItem2.png';
import randomLabItem3 from '../../public/assets/randomLabItem3.png';
import randomLabItem4 from '../../public/assets/randomLabItem4.png';
import * as S from './LaboratorySection.styles';

export default function LaboratorySection() {
  return (
    <>
      <HeaderSection
        backgroundSrc={Lab}
        header={'Laboratorium'}
        description={`Laboratorium Monitlab pracuje według najwyższych standardów i wykorzystuje najnowsze sprzęty i technologie laboratoryjne. Placówka jest obecnie częścią <a href="https://ppnt.poznan.pl/">Poznańskiego Parku Technologicznego w Poznaniu</a>, w budynku H, lokal 221.`}
      />
      <S.Row>
        <S.Picture>
          <img src={randomLabItem1} alt="jars" />
        </S.Picture>
        <S.Picture>
          <img src={randomLabItem2} alt="pH meter" />
        </S.Picture>
        <S.Picture>
          <img src={randomLabItem3} alt="pipet tips box" />
        </S.Picture>
        <S.Picture>
          <img src={randomLabItem4} alt="cheep pipets" />
        </S.Picture>
      </S.Row>
    </>
  );
}
