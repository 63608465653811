import { IResultsGenerator, ISingleResult } from "../components/ResultsGenerator/ResultsGenerator";
import { getDayMonthYearDateFromString, getHourMinuteTimeFromString } from "./date";
import { ISubmission } from "./types";


export const getSortedSubmissions = (submissions: ISubmission[]) => submissions.sort((a, b) => {
  const aVal = a.createdAt ? a.createdAt : 0;
  const bVal = b.createdAt ? b.createdAt : 0;
  return +new Date(aVal) - +new Date(bVal);
})


export const getPDFValues = (submission: ISubmission): IResultsGenerator => {
  const { location } = submission
  const results: ISingleResult[] = [];
  submission.samples.map(s => {
    const isDateTemporary = typeof s.timestamp === 'object' && s.timestamp
    const timeStamp = isDateTemporary ?  s.timestamp.toISOString() : s.timestamp

    const date = getDayMonthYearDateFromString(timeStamp)
    const time = getHourMinuteTimeFromString(timeStamp)
    return results.push({
      date: date ? date : "",
      time: time ? time : "",
      result: s.result || "",
      patientsCode: s.vialId,
      notes: s.note,
      personName: s.personName || "",
      personSurname: s.personSurname || "",
      personIdentification: s.personIdentification || ""
    })
  })
  return {
    address1: location ? location.name : "",
    address2: location ? location.secondaryName : "",
    address3: location ? location.street : "",
    address4: location ? `${location.zipCode} ${location.city}` : "",
    results
  }
}