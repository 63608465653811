import { AppState } from '../store'

export const authState = (state: AppState) => state.auth

export const authSelector = (state: AppState) => {
  const { isLoading, loginErrorMessage } = authState(state)
  return { isLoading, loginErrorMessage }
}

export const isUserLoggedIn = (state: AppState) => authState(state).isLoggedIn

export const useUserStatus = (state: AppState) => {
  const {
    user: { isAdmin, isActive },
  } = authState(state)
  return { isAdmin, isActive }
}

export const userData = (state: AppState) => authState(state).user

export const userBasicData = (state: AppState) => {
  const { email, name, surname } = authState(state).user
  return { name, surname, email }
}

export const usersEmail = (state: AppState) => userData(state).email

export const usersId = (state: AppState) => userData(state).id

// export const useUserLocations = (state: AppState) => userData(state).userLocation
