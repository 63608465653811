import HeaderSection from '../HeaderSection/HeaderSection';
// import WhyImportant from 'components/rework/WhyImportant/WhyImportant';
// import * as S from './RnD.styles';

const RnD = () => (
  <>
    <HeaderSection
      header={'Projekt B+R: Cel i założenia'}
      description={
        "Projekt B+R “Opracowanie metody HPLC oznaczania poziomów posakonazolu w osoczu ludzkim z przeznaczeniem do terapeutycznego monitorowania leku w celu poprawy bezpieczeństwa terapii grzybic układowych u pacjentów hematoonkologicznych” ma na celu powstanie prototypu, niezbędnego do racjonalizacji farmakoterapii pacjentów głównie onkologicznych.\n\nPomysł jest odpowiedzią na rosnące zapotrzebowanie w zakresie TDM (therapeutic drug monitoring), czyli postępowania polegającego na pomiarach stężenia leku we krwi oraz obliczaniu na podstawie tych wyników indywidualnych dawek dla pacjentów. Jest to praktyka stosowana z dużym powodzeniem już w wielu państwach Świata, powoli także i w Polsce."
      }
    />
  </>
  )
/* <S.Section>
    <S.InnerSectionWrapper>
      <S.Text>
        Projekt B+R “Opracowanie metody HPLC oznaczania poziomów posakonazolu w&nbsp;osoczu
        ludzkim z&nbsp;przeznaczeniem do terapeutycznego monitorowania leku w celu poprawy
        bezpieczeństwa terapii grzybic układowych u&nbsp;pacjentów hematoonkologicznych” ma na
        celu powstanie prototypu, niezbędnego do racjonalizacji farmakoterapii pacjentów głównie
        onkologicznych.
      </S.Text>
      <S.Text>
        Pomysł jest odpowiedzią na rosnące zapotrzebowanie w&nbsp;zakresie TDM (therapeutic drug
        monitoring), czyli postępowania polegającego na pomiarach stężenia leku we krwi oraz
        obliczaniu na podstawie tych wyników indywidualnych dawek dla pacjentów. Jest to praktyka
        stosowana z&nbsp;dużym powodzeniem już w&nbsp;wielu państwach Świata, powoli także
        i&nbsp;w&nbsp;Polsce.
      </S.Text>
    </S.InnerSectionWrapper>
    <S.Background />
  </S.Section> */

export default RnD;
