import UsersTable from '../components/Dashboard/UsersTable/UsersTable'
import { useDispatch } from 'react-redux'
import { getUsers, getLocations } from '../redux/data/actions'
import { useEffect } from 'react'

export default function UsersPage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLocations())
    dispatch(getUsers())
  }, [dispatch])
  return <UsersTable/>
}
