import * as S from './ResultsGenerator.styles'
import Logo from '../../public/rework/logo.png'
// import IconMail from '../../public/rework/mail.png'
// import IconPlace from '../../public/rework/place.png'
// import IconPhone from '../../public/rework/place.png'
//@ts-ignore
import RobotoBold from '../../public/fonts/Roboto-Bold.ttf'
//@ts-ignore
import RobotoRegular from '../../public/fonts/Roboto-Regular.ttf'

import { Document, Page, Font, Text, View, StyleSheet, Image } from '@react-pdf/renderer'

export interface ISingleResult {
  patientsCode: string
  date: string
  time: string
  result: string
  notes?: string
  personName?: string;
  personSurname?: string
  personIdentification?: string
}

export interface IResultsGenerator {
  address1: string
  address2: string
  address3: string
  address4: string
  results: ISingleResult[]
}

const ResultsGenerator = (props: IResultsGenerator) => {
  Font.register({
    family: 'Roboto',
    fontWeight: 'bold',
    src: RobotoBold,
  })

  Font.register({
    family: 'Roboto',
    src: RobotoRegular,
  })

  Font.register({
    family: 'Roboto',
    fontWeight: 'bold',
    src: RobotoBold,
  })
  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Roboto',
      color: '#0d3e8e',
      padding: '3%',
      fontSize: '10px',
    },
    wrapper: {
      color: '#0d3e8e',
      padding: '3%',
    },
    blueLine: {
      width: '100%',
      backgroundColor: '#0d3e8e',
      height: '2px',
    },
    redLine: {
      width: '100%',
      backgroundColor: '#c60a14',
      height: '2px',
    },
    headerSection: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      padding: '10px',
      alignItems: 'center',
      flexDirection: 'row',
    },
    break: {
      width: '100%',
      backgroundColor: '#aaa',
      height: '1px',
      // background: 'transparent',
      // width: '100%',
      // border: 'none',
      // borderTop: 'solid 2px #aaa',
      // backgroundColor: 'black'
    },
    bigWords: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      textTransform: 'uppercase',
    },
    headerLogo: {
      // height: '48px',
      // width: '310px',
      height: '24px',
      width: '155px',
    },
    resultsTitle: {
      fontSize: '18px',
      textAlign: 'center',
      padding: '16px',
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    boldText: {
      fontWeight: 'bold',
    },
    centerText: {
      textAlign: 'center',
    },
    section3: {
      display: 'flex',
      flexDirection: 'column',
    },
    section3Top: {
      display: 'flex',
      justifyContent: 'space-around',
      textAlign: 'center',
    },
    section4: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'row',
    },
    resultsValue: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    sectionWrapper: {
      padding: '20px',
    },
    footerRow: {
      display: 'flex',
    },
  })

  const { address1, address2, address3, address4, results } = props
  return (
    <Document>
      {results.map(({ patientsCode, date, time, result, notes, personName, personSurname, personIdentification  }, index) => (
        <Page size="A4" style={styles.page} key={index}>
          <S.Wrapper id="element-to-print" style={styles.wrapper}>
            <View style={styles.blueLine} />
            <View style={styles.headerSection}>
              <Image src={Logo} style={styles.headerLogo} />
              <View>
                <Text>MonitLab Sp. z o.o.</Text>
                <Text>ul. Rubież 46h</Text>
                <Text>61-612 Poznań</Text>
              </View>
            </View>
            <View style={styles.break} />
            <Text style={styles.resultsTitle}>Wyniki badania laboratoryjnego</Text>
            <View style={styles.break} />
            <View style={styles.sectionWrapper}>
              <Text style={styles.bigWords}>Jednostka kierująca:</Text>
              <View>
                <Text>{address1}</Text>
                <Text>{address2}</Text>
                <Text>{address3}</Text>
                <Text>{address4}</Text>
              </View>
              <View></View>
            </View>
            <View style={styles.break} />
            <View style={styles.sectionWrapper}>
              {/* <Text>
                Lekarz kierujący: <Text style={styles.boldText}>Lek. med. Marta Malczewska</Text>
              </Text> */}
              <Text>
                Data i godzina oznaczenia próbki:{' '}
                <Text style={styles.boldText}>
                  {date}, {time}
                </Text>
              </Text>
              <Text>
                Kod pacjenta: <Text style={styles.boldText}>{patientsCode}</Text>
              </Text>
              <Text>
                Pesel: <Text style={styles.boldText}>{personIdentification|| "---"}</Text>
              </Text>
              <Text>
                Imię i nazwisko: <Text style={styles.boldText}>{personName || "---"}{" "}{personSurname || "---"}</Text>
              </Text>
              <Text>
                Materiał: <Text style={styles.boldText}>osocze</Text>
              </Text>
              <Text>
                Nazwa badania: <Text style={styles.boldText}>oznaczenie stężenia pozakonazolu</Text>
              </Text>
            </View>
            <View style={styles.break} />
            <View style={styles.sectionWrapper}>
              <View style={styles.section3}>
                <View style={styles.section3Top}>
                  <Text style={styles.resultsValue}>Wynik: {result} mg/L</Text>
                </View>
                <Text>
                  Zakres referencyjny: <Text style={styles.boldText}>0,7- 3,0 mg/L</Text>
                </Text>
                <Text>
                  Metoda oznaczenia: <Text style={styles.boldText}>HPLC-FLD via Dionex Ultimate 3000</Text>
                </Text>
                {notes && (
                  <Text>
                    Uwagi: <Text style={styles.boldText}>{notes}</Text>
                  </Text>
                )}
              </View>
            </View>

            <View style={styles.break} />
            <View style={styles.sectionWrapper}>
              <View style={styles.section4}>
                <View>
                  <Text style={styles.centerText}>Wykonał/a:</Text>
                  <Text>dr n. farm. Matylda Resztak</Text>
                </View>
                <View>
                  <Text style={styles.centerText}>Zatwierdził/a:</Text>
                  <Text>dr n. farm. Paulina Zalewska</Text>
                </View>
              </View>
            </View>
            <View>
              <S.LogoWrapper>
                <View style={styles.redLine} />
                <S.LogoSmall />
              </S.LogoWrapper>
            </View>

            <View style={styles.sectionWrapper}>
              <View>
                <View style={styles.footerRow}>
                  {/* <Image src={IconPhone} /> */}
                  <Text>+48 505 543 170</Text>
                </View>
                <View style={styles.footerRow}>
                  {/* <Image src={IconMail} /> */}
                  <Text>biuro@MonitLab.pl</Text>
                </View>
                <View style={styles.footerRow}>
                  {/* <Image src={IconPlace} /> */}
                  <Text>BIURO - Plac Jana Kilińskiego 2, 35-005 Rzeszów</Text>
                  <Text>Laboratorium - ul. Rubież 46h, 61-216 Poznań</Text>
                </View>
              </View>
            </View>
          </S.Wrapper>
        </Page>
      ))}
    </Document>
  )
}

export default ResultsGenerator
