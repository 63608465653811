import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  min-height: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
  @media (min-width: 800px) {
    padding: 2rem;
  }
`;

export const Header = styled.h1`
  font-size: 2rem;
  font-family: Poppins;
  font-weight: 400;
  margin-right: 6px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  @media (min-width: 600px) {
    font-size: 3rem;
  }
`;

export const Body = styled.div`
  padding: 1rem 0;
  box-sizing: border-box;
  overflow: visible;
  @media (min-width: 600px) {
    padding: 1rem;
  }
`;

export const Link = styled.div`
  display: flex;
  border-radius: 4px;
  color: white;
  background: linear-gradient(270deg, #797EF6 -4.12%, #7DD6F6 102.94%);
  font-family: Poppins;
  font-weight: 600;
  width: fit-content;
  transition: 500ms;
  font-size: 1rem;
  padding: 12px;
  :hover {
    box-shadow: 5px 5px 20px #7DD6F6;
  }
  ;
`

export const IconContainer = styled.div`
padding-right: 6px;`