import { createBrowserHistory } from 'history'
import { createTheme, MuiThemeProvider } from '@material-ui/core'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import AboutUsSection from './components/AboutUsSection/AboutUsSection'
import AdvantagesSection from './components/AdvantagesSection/AdvantagesSection'
import ComputersRequest from './components/Requests/ComputersRequest'
import IndexPage from './pages/IndexPage'
import LabEquipment from './components/Requests/LabEquipment'
import LaboratoryRequest from './components/Requests/LaboratoryRequest'
import LaboratorySection from './components/LaboratorySection/LaboratorySection'
import Layout from './components/Common/Layout/Layout'
import Login from './components/Login/Login'
import RnD from './components/RnD/RnD'
import ScrollToTop from './utils/ScrollToTop'
import TDM from './pages/TDM'
import Theme from './Theme'
import UserRouter from './UserRouter'
import PasswordResetValidationPage from './components/PasswordResetValidationPage/PasswordResetValidationPage'
import PasswordResetConfirmationPage from './components/PasswordResetConfirmationPage/PasswordResetConfirmationPage'
import PasswordResetPage from './components/PasswordResetPage/PasswordResetPage'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as S from './App.styles'

export const history = createBrowserHistory()
const App = () => {
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: Theme.mainBlue,
      },
      secondary: {
        main: Theme.secondaryBlue,
      },
    },
  })
  return (
    <ThemeProvider theme={Theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Router history={history}>
        <ToastContainer/>
          <ScrollToTop />
          <Layout>
            <S.Wrapper>
              <Switch>
                <Route path="/" exact component={IndexPage} />
                <Route path="/research-and-development" exact component={RnD} />
                <Route path="/tdm" exact component={TDM} />
                <Route path="/request/computers" exact component={ComputersRequest} />
                <Route path="/request/lab-equipment" exact component={LabEquipment} />
                <Route path="/request/laboratory" exact component={LaboratoryRequest} />
                <Route path="/about-us" exact component={AboutUsSection} />
                <Route path="/advantages" exact component={AdvantagesSection} />
                <Route path="/laboratory" exact component={LaboratorySection} />
                <Route path="/login" exact component={Login} />
                <Route path="/dashboard" component={UserRouter} />
                <Route exact path="/password-reset" component={PasswordResetPage} />
                <Route path="/password-reset/:code" component={PasswordResetValidationPage} />
                <Route path="/password-reset-confirm" component={PasswordResetConfirmationPage} />
                <Route path="*" component={() => <Redirect to='/' />} />
              </Switch>
            </S.Wrapper>
          </Layout>
        </Router>
      </MuiThemeProvider>
    </ThemeProvider>
  )
}
export default App
