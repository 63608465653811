import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 75px;
  max-width: 1286px;
  width: 100%;
  margin: 0 auto 4rem;
  padding: 0 2rem 4rem;
`;

export const Picture = styled.div`
  width: 280px;
  height: 360px;
  margin: 0.5rem;
  :hover {
    transform: scale(1.2);
    box-shadow: 0 0 1rem 2rem ${({ theme: { mainBackground } }) => mainBackground};
    z-index: 1;
  }
  transition: 250ms;
  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
