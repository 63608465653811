import * as S from './Navbar.styles'

export default function MenuIcon({ isOpen = false }: { isOpen?: boolean }) {
  return (
    <S.IconWrapper>
      <S.IconEl1 isOpen={isOpen} />
      <S.IconEl2 isOpen={isOpen} />
      <S.IconEl3 isOpen={isOpen} />
    </S.IconWrapper>
  )
}
