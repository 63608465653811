import { logout } from '../../../redux/auth/actions'
import { useDispatch } from 'react-redux'
import * as S from './UserNavbar.styles'

interface UserNavbarProps {
  name: string
  surname: string
}

export default function UserNavbar({ name, surname }: UserNavbarProps) {
  const dispatch = useDispatch()
  return (
    <S.Wrapper>
      <S.TextWrapper>
        <S.TextRow>{name ? name : surname}</S.TextRow>
        <S.Button onClick={() => dispatch(logout())}>Wyloguj się</S.Button>
      </S.TextWrapper>
    </S.Wrapper>
  )
}
