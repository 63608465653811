import Submission from '../components/Dashboard/Submission/Submission'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getLocations, resetSubmission } from '../redux/data/actions'
import { isFetchingSelector } from '../redux/data/selectors'
import Spinner from '../components/Common/Spinner/Spinner'

const New = () => {
  const isFetchingData = useSelector(isFetchingSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLocations())
    dispatch(resetSubmission())
  }, [dispatch])
  if (isFetchingData) return <Spinner />
  return <Submission />
}

export default New