import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface IConfirmationDialog {
  isOpen: boolean,
  body: string,
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog = ({ isOpen, onCancel, onConfirm, body }: IConfirmationDialog) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">
        Uwaga
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel}>
          Nie
        </Button>
        <Button onClick={onConfirm}>Tak</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;