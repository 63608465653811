import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubmissionsList from '../components/Dashboard/SubmissionsList/SubmissionsList/SubmissionsList'
import { getSubmissions } from '../redux/data/actions'
import { submissionsSelector } from '../redux/data/selectors'

export default function SubmissionsPage() {
  const { submissions } = useSelector(submissionsSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getSubmissions())
  }, [dispatch])
  return <SubmissionsList submissions={submissions} />
}
