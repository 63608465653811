import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import { createUser, setCreateUserErrorMessage } from '../../../redux/data/actions'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingSelector, useCreateUserErrorMessage } from '../../../redux/data/selectors'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import * as S from '../Submission/Submission.styles'

const NewUserForm = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const errorMessage = useSelector(useCreateUserErrorMessage)
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const data = {
      name,
      surname,
      email: email.toLowerCase(),
      password,
      isAdmin
    }
    dispatch(createUser(data))
  }

  const onClearForm = () => setIsDialogOpen(true)

  const clearForm = () => {
    setName('')
    setSurname('')
    setEmail('')
    setPassword('')
    setIsAdmin(false)
  }

  const onCancel = () => setIsDialogOpen(false)
  const onConfirm = () => {
    clearForm();
    setIsDialogOpen(false)
  }

  useEffect(() => {
    dispatch(setCreateUserErrorMessage(''))
  }, [dispatch])

  const isButtonDisabled = !name || !surname || !email || !password

  return (
    <DashboardWrapper header={'Dodaj nowego użytkownika'}>
      <ConfirmationDialog
        body={'Czy na pewno chcesz wyczyścić formularz?'}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <S.FormWrapper onSubmit={handleSubmit} autoComplete="off">
        <S.Wrapper>
          <S.Row>
            <S.InputLabel>Imię:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                type={'text'}
                required
                value={name}
                onChange={(e: ChangeEvent<{ value: string }>) => setName(e.target.value)}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Nazwisko:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                type={'text'}
                required
                value={surname}
                onChange={(e: any) => setSurname(e.target.value)}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Email:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                required
                type={'email'}
                value={email}
                onChange={(e: ChangeEvent<{ value: string }>) => setEmail(e.target.value)}
                autoComplete="off"
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Hasło:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                minLength={5}
                required
                type={'password'}
                value={password}
                onChange={(e: ChangeEvent<{ value: string }>) => setPassword(e.target.value)}
                autoComplete="new-password"
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Administrator</S.InputLabel>
            <S.InputWrapper isTransparent>
              <S.Checkbox
                required={false}
                type={'checkbox'}
                disabled={false}
                checked={isAdmin}
                onChange={() => setIsAdmin(!isAdmin)}
              />
            </S.InputWrapper>
          </S.Row>
        </S.Wrapper>
        { errorMessage && (
        <S.ErrorWrapper>Wystąpił błąd</S.ErrorWrapper>
        )}
        <S.Row hasNoBorder>
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            label={'Wyczyść'}
            onClick={onClearForm}
            theme={'cancel'}
          />
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            type="submit"
            theme={'save'}
            label={'Zapisz'}
            disabled={isButtonDisabled}
          />
        </S.Row>
      </S.FormWrapper>
    </DashboardWrapper>
  )
}

export default NewUserForm
