import Banner from '../../public/rework/Banner.png';
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper';
import Check from '../../public/rework/icons/check.svg'
import * as S from './ExplanationSection.styles';

export default function ExplanationSection() {
  return (
    <WidePhotoSectionWrapper
      title={'Indywidualne podejście'}
      photo={Banner}
      alt={'background image'}
    >
      <S.BodyWrapper>
        <S.TillWrapper>
          <S.IconWrapper>
            <S.Icon src={Check} alt={'check icon'} />
          </S.IconWrapper>
          <S.Label>
            Z punktu widzenia skuteczności terapii ważne jest nie tylko to, jaką dawkę leku przyjmie
            pacjent, ale także to, jakie stężenie osiąga lek we krwi. Jeśli jest go zbyt mało,
            leczenie nie będzie skuteczne.
          </S.Label>
        </S.TillWrapper>
        <S.TillWrapper>
          <S.IconWrapper1>
            <S.Icon src={Check} alt={'check icon'} />
          </S.IconWrapper1>
          <S.Label>
            Zbyt wysokie stężenie może z kolei zwiększyć ryzyko wystąpienia objawów niepożądanych,
            a&nbsp;dodatkowo niepotrzebnie obciąża narządy metabolizujące lek (wątrobę i nerki) a
            także podnosi próg wrażliwości organizmu, a więc wymusza stosowanie coraz większych
            dawek leku.
          </S.Label>
        </S.TillWrapper>
        <S.TillWrapper>
          <S.IconWrapper2>
            <S.Icon src={Check} alt={'check icon'} />
          </S.IconWrapper2>
          <S.Label>
            Zagrożeniem jest także wzrost oporności patogenów na skutek zbyt niskich poziomów leku,
            jak również działania niepożądane jakie mogą być wynikiem zbyt wysokich stężeń leku we
            krwi (antybiotyki, leki przeciwgrzybicze).
          </S.Label>
        </S.TillWrapper>
        <S.TillWrapper>
          <S.IconWrapper3>
            <S.Icon src={Check} alt={'check icon'} />
          </S.IconWrapper3>
          <S.Label>
            Rozwiązaniem tego problemu jest monitorowanie stężenia leku we krwi pacjentów, aby na
            tej podstawie móc dobrać dawkowanie leku{' '}
            <S.Red>indywidualnie dla każdego pacjenta</S.Red>, co umożliwia stworzona przez nas
            metoda HPLC.
          </S.Label>
        </S.TillWrapper>
      </S.BodyWrapper>
    </WidePhotoSectionWrapper>
  );
}
