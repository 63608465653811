import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
  padding: 0 0 6rem;
`

export const BodyWrapper = styled.div`
  max-width: 1350px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  display: flex;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
  box-sizing: border-box;
`

export const PhotoColumn = styled.aside`
  width: 100%;
  margin: 0 0 2rem;
  @media (min-width: 1024px) {
    min-width: 40%;
    width: 40%;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: left;
`

export const TextColumn = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  flex-grow: 1;
`

export const Header = styled.h2`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  margin: 0 0 2rem;
  font-size: 2rem;
  @media (min-width: 500px) {
    font-size: 3rem;
  }
  @media (min-width: 1024px) {
    margin: 0 2rem 2rem;
  }
`

export const FormWrapper = styled.form`
  width: 100%;
  padding: 0;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const CommonInput = css`
  width: 100%;
  padding: 1rem 1.5rem;
  border: none;
  border-radius: 4px;
  margin: 0 0 1rem;
  font-family: Poppins;
  font-size: 1rem;
  :focus {
    outline: none;
  }
`

export const Input = styled.input`
  ${CommonInput}
  background-color: #fff;
  box-shadow: 5px 5px 15px ${({ theme: { mainBlue } }) => mainBlue}55;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :placeholder {
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
`

export const TextArea = styled.textarea`
  ${CommonInput}
  background-color: #fff;
  box-shadow: 5px 5px 15px ${({ theme: { mainBlue } }) => mainBlue}55;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :placeholder {
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
  resize: none;
  height: 300px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 2rem auto;
`

export const Label = styled.p`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 0.75rem;
  a {
    font-weight: 500;
    color: ${({ theme: { gradient1 } }) => gradient1};
  }
`

export const Checkbox = styled.input`
  background-color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border: none;
  margin: 0 1rem 0 0;
  cursor: pointer;
`

export const SubmitButton = styled.button`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem 5rem;
  white-space: nowrap;
  border-radius: 8px;
  color: ${({ theme: { white } }) => white};
  background: ${({ theme: { gradient1, gradient2 } }) =>
    `linear-gradient(270deg, ${gradient1} -4.12%, ${gradient2} 102.94%)`};
  :hover {
    box-shadow: ${({ theme: { gradient1 } }) => `0 0 15px 2px ${gradient1}`};
    color: ${({ theme: { black } }) => black};
    text-shadow: 0 0 24px ${({ theme: { white } }) => white};
  }
  transition: 250ms;
  margin: 1rem auto 0;
  @media (min-width: 1024px) {
    margin: 2rem auto 0;
  }
  :disabled {
    filter: grayscale(0.6);
  }
  transition: filter 250ms;
  cursor: pointer;
`

// export const DataBox = styled.p`
//   ${U.font30}
//   line-height: 35px;
//   color: ${themes.default.main};
//   margin: 0 8px 32px 0;
//   font-weight: 800;
//   min-width: 258px;
// `;

// export const WideDataBox = styled(DataBox)`
//   width: 100%;
// `;

// export const Row = styled.div`
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-bottom: 45px;
// `;

// export const Column = styled.div`
//   ${U.column}
// `;

// export const Link = styled.a`
//   ${U.font14}
//   text-decoration: none;
//   color: ${themes.default.main};
//   display: flex;
//   width: 304px;
//   align-items: center;
//   margin-bottom: 36px;
// `;

// const IconCommonProps = `
//   width: 38px;
//   height: 38px;
//   margin-right: 16px;
//   color: ${themes.default.main};
// `;

// export const MapPinIcon = styled(FaMapMarkerAlt)`
//   ${IconCommonProps}
// `;

// export const MobileIcon = styled(FaMobileAlt)`
//   ${IconCommonProps}
// `;

// export const MailIcon = styled(FaRegEnvelope)`
//   ${IconCommonProps}
// `;

// const MediaIconProps = `
//   width: 32px;
//   height: 32px;
//   margin: 0 4px;
//   margin-right: 16px;
//   color: ${themes.default.main};
// `;

// export const Twitter = styled(FaTwitter)`
//   ${MediaIconProps}
// `;

// export const LinkedIn = styled(FaLinkedin)`
//   ${MediaIconProps}
// `;

// export const Facebook = styled(FaFacebookSquare)`
//   ${MediaIconProps}
// `;

// export const MediaRow = styled.div`
//   justify-content: flex-start;
//   display: flex;
//   width: 100%;
//   margin-bottom: 24px;
// `;

// export const ContactUsForm = styled.form`
//   max-width: 312px;
//   width: 100%;
//   ${U.column}
//   @media(max-width: 1048px) {
//     margin: 0 auto;
//   }
// `;

// export const ContactHeader = styled.h4`
//   ${U.font24}
//   letter-spacing: 0.1px;
//   margin: 0 0 14px;
//   color: ${themes.default.secondary};
//   width: 100%;
//   font-weight: 400;
// `;

// export const Input = styled.input`
//   ${U.font14}
//   border: 1px solid #e8e8e8;
//   box-sizing: border-box;
//   background-color: ${themes.default.secondaryBackground};
//   border-radius: 40px;
//   margin-bottom: 12px;
//   width: 100%;
//   height: 60px;
//   padding: 0 20px;
//   :focus {
//     outline: none;
//   }
// `;

// export const Textarea = styled.textarea`
//   ${U.font14}
//   border: 1px solid #e8e8e8;
//   color: #18171d;
//   resize: none;
//   width: 100%;
//   height: 154px;
//   margin-bottom: 24px;
//   padding: 20px;
//   box-sizing: border-box;
//   background-color: ${themes.default.secondaryBackground};
//   border-radius: 40px;
//   :focus {
//     outline: none;
//   }
// `;

// export const Check = styled.input`
//   width: 18px;
//   height: 18px;
//   cursor: pointer;
// `;

// export const AcceptText = styled.p`
//   ${U.font12}
//   line-height: 16px;
//   letter-spacing: 0.1px;
//   width: 260px;
//   text-align: justify;
// `;

// export const WideRow = styled(Row)`
//   width: 100%;
//   align-items: flex-start;
//   margin-bottom: 0;
// `;

// export const SubmitButton = styled.button`
//   border: none;
//   background-color: ${themes.default.main};
//   color: ${themes.default.background};
//   ${U.font24}
//   width: 100%;
//   cursor: pointer;
//   height: 68px;
//   border-radius: 34px;
//   :disabled {
//     opacity: 0.5;
//     cursor: default;
//   }
// `;
