import styled from 'styled-components';

export const WhiteBackgroundText = styled.p`
  width: 100%;
  background-color: ${({theme: { white }}) => white};
  color: ${({theme: { mainBlue }}) => mainBlue};
  font-family: Roboto;
  font-size: 1rem;
  line-height: 160%;
  padding: 2rem;
  border-radius: 4px;
  margin-bottom: 8rem;
`

export const TeamPhoto = styled.div`
  width: 100%;
  margin: 0 0 1rem;
  @media(min-width: 600px) {
    margin: 0 0 2rem;
  }
  img {
    border-radius: 4px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`
