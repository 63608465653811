import LocationsTable from '../components/Dashboard/LocationsTable/LocationsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getLocations } from '../redux/data/actions'
import { locationsSelector } from '../redux/data/selectors'
import { useEffect } from 'react'

export default function LocationsPage() {
  const { locations } = useSelector(locationsSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLocations())
  }, [dispatch])
  return <LocationsTable locations={locations} />
}
