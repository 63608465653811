import { ChangeEvent, FormEvent, useState, useEffect } from 'react'
import { ILocation } from '../LocationsTable/LocationsTable'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import { createLocation, setCreateLocationErrorMessage } from '../../../redux/data/actions'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingSelector, useCreateLocationErrorMessage } from '../../../redux/data/selectors'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import { updateLocation } from '../../../api/dataAPI'
import { history } from '../../../App'
import { toast } from 'react-toastify'
import * as S from '../Submission/Submission.styles'

const NewLocations = ({ data }: { data?: ILocation }) =>  {
  const [name, setName] = useState('')
  const [secondaryName, setSecondaryName] = useState('')
  const [street, setStreet] = useState('')
  const [city, setCity] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)
  const errorMessage = useSelector(useCreateLocationErrorMessage)


  useEffect(() => {
    if (!data) {
      return
    }
    setName(data.name)
    setSecondaryName(data.secondaryName)
    setStreet(data.street)
    setCity(data.city)
    setZipCode(data.zipCode)
  }, [data])

  const saveNewLocation = async () => {
    const data = {
      name,
      secondaryName,
      street,
      city,
      zipCode,
    }
    dispatch(createLocation(data))
  }

  const update = async () => {
    if (!data) {
      return null
    }
    const updatedData = {
      id: data.id,
      name,
      secondaryName,
      street,
      city,
      zipCode,
    }
    await updateLocation(updatedData)
    toast.success('Lokalizacja pomyślnie zaktualizowana')
    history.push('/dashboard/locations')
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (data && data.id) {
      return update()
    }
    return saveNewLocation()
  }

  const onClearForm = () => setIsDialogOpen(true)

  const clearForm = () => {
    setName('')
    setSecondaryName('')
    setStreet('')
    setCity('')
    setZipCode('')
  }

  const onCancel = () => setIsDialogOpen(false)
  const onConfirm = () => {
    clearForm();
    setIsDialogOpen(false)
  }

  const resetError = () => {
    dispatch(setCreateLocationErrorMessage(''))
  }

  useEffect(() => {
    dispatch(setCreateLocationErrorMessage(''))
  }, [dispatch])
  const isFormEmpty = !name && !secondaryName && !street && !city && !zipCode

  return (
    <DashboardWrapper header={data ? 'Edytuj placówkę' : 'Dodaj nową placówkę'}>
      <ConfirmationDialog
        body={"Czy na pewno chcesz wyczyścic formularz?"}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <S.FormWrapper onSubmit={handleSubmit}>
        <S.Wrapper>
          <S.Row>
            <S.InputLabel>Nazwa</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                required
                value={name}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  setName(e.target.value)
                  resetError();
                }}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Nazwa szczegółowa</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                value={secondaryName}
                onChange={(e: ChangeEvent<{ value: string }>) => {
                  setSecondaryName(e.target.value)
                  resetError()
                }}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Ulica</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                type={'text'}
                required
                value={street}
                onChange={(e: any) => { setStreet(e.target.value) }}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Miasto</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                required
                type={'text'}
                value={city}
                onChange={(e: ChangeEvent<{ value: string }>) => setCity(e.target.value)}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Kod pocztowy</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                required
                type={'text'}
                value={zipCode}
                onChange={(e: ChangeEvent<{ value: string }>) => setZipCode(e.target.value)}
              />
            </S.InputWrapper>
          </S.Row>
        </S.Wrapper>
        {errorMessage && (
          <S.ErrorWrapper>Podana placówka już istnieje</S.ErrorWrapper>
        )}
        <S.Row hasNoBorder>
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            label={'Wyczyść'}
            onClick={onClearForm}
            theme={'cancel'}
            disabled={isFormEmpty}
          />
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            disabled={!name || !street || !city || !zipCode}
            type="submit"
            theme={'save'}
            label={data ? 'Zapisz' : 'Dodaj'}
          />
        </S.Row>
      </S.FormWrapper>
    </DashboardWrapper>
  )
}

export default NewLocations
