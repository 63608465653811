import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { isUserLoggedIn, useUserStatus } from './redux/auth/selectors'
import EditLocationPage from './pages/EditLocationPage'
import EditSubmissionPage from './pages/EditSubmissionPage'
import LocationPage from './pages/LocationPage'
import NewLocations from './components/Dashboard/NewLocations/NewLocations'
import NewSubmissionPage from './pages/NewSubmissionPage'
import NewUserForm from './components/Dashboard/NewUserForm/NewUserForm'
import SettingsPage from './pages/SettingsPage'
import SubmissionsPage from './pages/SubmissionsPage'
import UsersPage from './pages/UsersPage'
import { history } from './App'

export default function UserRouter() {
  const isLoggedIn = useSelector(isUserLoggedIn)
  const { isAdmin } = useSelector(useUserStatus)
  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/login')
    }
  })

  const GotoDashboard = () => <Redirect to='/dashboard/submissions' />

  return (
    <Switch>
      <Route path="/dashboard" exact component={GotoDashboard} />
      <Route path="/dashboard/settings" exact component={SettingsPage} />
      <Route path="/dashboard/users" exact component={isAdmin ? UsersPage : GotoDashboard} />
      <Route path="/dashboard/users/new" exact component={isAdmin ? NewUserForm : GotoDashboard} />
      <Route path="/dashboard/locations" exact component={LocationPage} />
      <Route path="/dashboard/locations/new" exact component={NewLocations} />
      <Route path="/dashboard/locations/:locationId" exact component={EditLocationPage} />
      <Route path="/dashboard/submissions" exact component={SubmissionsPage} />
      <Route path="/dashboard/submissions/new" exact component={isAdmin ? GotoDashboard : NewSubmissionPage} />
      <Route path="/dashboard/submissions/:submissionId" exact component={EditSubmissionPage} />
    </Switch>
  )
}
