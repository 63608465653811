import pills from '../../public/rework/pills.jpeg';
import PictureAndListSection from '../PictureAndListSection/PictureAndListSection';
import * as S from './TherapyFactors.styles';

const TherapyFactors = () => (
  <S.Wrapper>
    <PictureAndListSection
      isContain
      title={'Kluczowe czynniki wpływające na skuteczność terapii'}
      alt={'three advantages for a patient'}
      image={pills}
      listItems={[`Odpowiednie dawkowanie leku,`, `Stężenie, jakie lek osiąga we krwi.`]}
    />
  </S.Wrapper>
);

export default TherapyFactors;
