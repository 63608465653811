import { useEffect } from 'react'
import Submission from '../components/Dashboard/Submission/Submission'
import { useDispatch } from 'react-redux'
import { getLocations } from '../redux/data/actions'
import { getSubmission } from '../redux/data/actions'
import { useParams } from 'react-router'

const EditSubmissionPage = () => {
  const { submissionId } = useParams() as { submissionId?: string }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLocations())
    if (submissionId) {
      dispatch(getSubmission(submissionId))
    }
  }, [dispatch, submissionId])
  return <Submission />
}

export default EditSubmissionPage
