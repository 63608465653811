import thermofisher1 from '../../public/assets/thermofisher1.png';
import thermofisher2 from '../../public/assets/thermofisher2.png';
import SVG1 from '../../public/assets/SVG1.svg';
import SVG2 from '../../public/assets/SVG2.svg';
import SVG3 from '../../public/assets/SVG3.svg';
import SVG4 from '../../public/assets/SVG4.svg';
import PictureAndListSection from '../PictureAndListSection/PictureAndListSection';
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper';
import * as S from './AdvantagesSection.styles';

const AdvantagesSection = () => (
  <WidePhotoSectionWrapper title={'Zalety usługi'} photo={thermofisher1}>
    <PictureAndListSection
      image={thermofisher1}
      isLeft={false}
      alt={'special equipment 1'}
      listItems={[
        `Nowatorska, pierwsza w Polsce metoda oznaczania pozakonazolu pozwala na kontrolowaną, dostosowaną do pacjenta, bezpieczną a\u00A0co za tym idzie skuteczną terapię lekiem.`,
        `Laboratorium MonitLab wykonuje oznaczenia w\u00A0bardzo krótkim czasie do 48h od otrzymania próbki osocza.`,
      ]}
    />
    <PictureAndListSection
      image={thermofisher2}
      alt={'special equipment 2'}
      listItems={[
        ` Niezwykle mała ilość osocza, wykorzystywana do oznaczeń nie obciąża organizmu pacjenta.`,
        `Metoda oznaczania pozakonazolu jest metodą bardzo dokładną, precyzyjną i\u00A0spełnia wymagania walidacyjne stawiane metodom analitycznym.`,
      ]}
    />
    <S.Row>
      <S.SimpleSvg>
        <img alt={'feature 1'} src={SVG1} />
      </S.SimpleSvg>
      <S.SimpleSvg>
        <img alt={'feature 2'} src={SVG2} />
      </S.SimpleSvg>
      <S.SimpleSvg>
        <img alt={'feature 3'} src={SVG3} />
      </S.SimpleSvg>
      <S.SimpleSvg>
        <img alt={'feature 4'} src={SVG4} />
      </S.SimpleSvg>
    </S.Row>
  </WidePhotoSectionWrapper>
);

export default AdvantagesSection;
