import { updateUsersStatus, deleteUser } from '../../../redux/data/actions'
import { IoMdArrowDropup } from 'react-icons/io'
import { isLoadingSelector, useLocations, usersSelector } from '../../../redux/data/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import colorTheme from '../../../Theme'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import MaterialTable from 'material-table'
import { IUser } from '../../../redux/auth/types'
import { BiPlus } from 'react-icons/bi'
import MultipleSelectCheckmarks from '../../LocationsSelect/LocationsSelect'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import * as C from '../Submission/Submission.styles'
import * as S from './UsersTable.styles'
import useMediaQuery from '../../../lib/hooks/useMediaQuery'

export default function UsersTable() {
  const [currentUsers, setCurrentUsers] = useState<IUser[]>([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState("");
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)
  const locations = useSelector(useLocations)
  const { users } = useSelector(usersSelector)

  useEffect(() => {
    setCurrentUsers(users)
  }, [users])

  const cellStyle = {
    backgroundColor: colorTheme.titaniumWhite,
    color: '#52575C',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    lineHeight: '140%',
    letterSpacing: '0.09px',
  }

  const onDeleteUser = (id: string) => {
    setDeleteUserId(id)
    setIsDialogOpen(true)
  }

  const onConfirm = () => {
    if (!deleteUserId) {
      return;
    }
    dispatch(deleteUser(deleteUserId))
    setIsDialogOpen(false)
  }

  const onCancel = () => setIsDialogOpen(false)

  const showEmptyUsersMessage = currentUsers.length <= 0;
  const isDesktop = useMediaQuery('(min-width: 1024px')

  return (
    <DashboardWrapper header={'Użytkownicy'} href={'/dashboard/users/new'} linkText={'Dodaj'} icon={<BiPlus />}>
      <ConfirmationDialog
        body={"Czy na pewno chcesz usunąć użytkownika?"}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <MaterialTable
        icons={{
          // @ts-ignore
          SortArrow: IoMdArrowDropup,
        }}
        options={{
          maxBodyHeight: isDesktop ? '80vh': '55vh',
          showEmptyDataSourceMessage: false,
          headerStyle: {
            zIndex: 1,
            backgroundColor: '#f5f5f5',
            color: colorTheme.mainBlue,
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '1rem',
            boxSizing: 'border-box',
            width: '100%',
          },
          selection: false,
          toolbar: false,
          paging: false,
          exportButton: false,
          rowStyle: {
            height: 10,
            position: 'relative',
          },
        }}
        columns={[
          {
            title: 'E-mail',
            field: 'email',
            cellStyle,
          },
          {
            title: 'Imię',
            field: 'name',
            cellStyle,
          },
          {
            title: 'Nazwisko',
            field: 'surname',
            cellStyle,
          },
          {
            title: 'Status',
            field: 'isActive',
            cellStyle,
            render: ({ isActive, isAdmin }: IUser) => <div>{isAdmin ? 'Admin' : isActive ? 'Aktywny' : 'Zablokowany'}</div>,
          },
          {
            title: 'Lokalizacje',
            field: 'localization',
            cellStyle,
            render:
              ({ id, userLocation }: IUser) => locations.length > 0 ? (
                <div>
                  <MultipleSelectCheckmarks
                    userId={id}
                    locations={locations}
                    initialLocations={userLocation}
                  />
                </div>) : 'Brak placówek',
          },
          {
            title: 'Akcje',
            cellStyle,
            render: ({ id, isActive }: IUser) => (
              <C.CommonButtonsContainer>
                <div>
                  <CommonButton
                    styles={'width: 85px; margin: 0 0 1rem;'}
                    labelStyles={'width: 85px;'}
                    label={isActive ? 'Zablokuj' : 'Odblokuj'}
                    onClick={() => dispatch(updateUsersStatus({ id, isActive: !isActive }))}
                    isLoading={isLoading}
                  />
                </div>
                <div>
                  <CommonButton
                    styles={'width: 85px; margin: 0 0 1rem;'}
                    labelStyles={'width: 85px;'}
                    theme={"delete"}
                    label={"Usuń"}
                    onClick={() => onDeleteUser(id)}
                    isLoading={isLoading}
                  />
                </div>
              </C.CommonButtonsContainer>
            ),
          },
        ]}
        data={currentUsers}
      />
      <S.EmptyMessageWrapper>
        {showEmptyUsersMessage && (
          <S.EmptyMessageText>
            Brak wpisów
          </S.EmptyMessageText>
        )}
      </S.EmptyMessageWrapper>
    </DashboardWrapper>
  )
}
