import { ChangeEvent, FormEvent, useState } from 'react';
import Pointing from '../../public/rework/pointer.jpeg';
import useMediaQuery from '../../lib/hooks/useMediaQuery';
import CommonButton from '../Common/CommonButton/CommonButton';
import * as S from './ContactSection.styles';
import { addNewContact } from '../../api/dataAPI';

export default function ContactSection() {
  const isDesktop = useMediaQuery('(min-width: 1024px');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await addNewContact({ email, name, message })
    setName('')
    setEmail('')
    setMessage('')
  };

  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.TextColumn>
          <S.Header>Formularz kontaktowy</S.Header>
          <S.FormWrapper onSubmit={onSubmit}>
            <S.Input
              required
              placeholder={'Imie i nazwisko'}
              value={name}
              type={'text'}
              onChange={(e: ChangeEvent<{ value: string }>) => setName(e.target.value)}
            />
            <S.Input
              required
              placeholder={'Email'}
              value={email}
              type={'mail'}
              onChange={(e: ChangeEvent<{ value: string }>) => setEmail(e.target.value)}
            />
            <S.TextArea
              required
              placeholder={'Wiadomość'}
              value={message}
              onChange={(e: ChangeEvent<{ value: string }>) => setMessage(e.target.value)}
            />
            <S.Row>
              <S.Checkbox required type={'checkbox'} />
              {/* <S.Label>
                Wyrażam zgodę na przetwarzanie moich danych osobowych przez Monitlab Sp. z o.o.
                zgodnie z{' '}
                <Link to={'/'}>
                  Polityka Prywatności
                </Link>
                , której treść akceptuję.
              </S.Label> */}
              <S.Label>
                Wyrażam zgodę na przetwarzanie moich danych osobowych przez Monitlab Sp. z o.o.
              </S.Label>
            </S.Row>
            <CommonButton
              styles={`margin: 0 auto;`}
              labelStyles={`padding: 1rem 2rem;`}
              disabled={!name || !email || !message}
              type='submit'
              label={'Wyślij'}
            />
          </S.FormWrapper>
        </S.TextColumn>
        {isDesktop && (
          <S.PhotoColumn>
            <S.Image src={Pointing} alt={'laboratory worker is pointing'} />
          </S.PhotoColumn>
        )}
      </S.BodyWrapper>
    </S.Wrapper>
  );
}
