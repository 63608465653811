import Till from '../partials/Till/Till';
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper';
import * as S from './Request.styles';

const LaboratoryRequest = () => {
  return (
    <WidePhotoSectionWrapper title={'Laboratorium 30m<sup>2</sup>'}>
      <S.Wrapper>
        <S.MessageBody>
          <S.MessageText>04 Maj 2020</S.MessageText>
          <S.MessageText>Szanowni Państwo,</S.MessageText>
          <S.MessageText>
            Jesteśmy zainteresowani wynajęciem powierzchni laboratoryjnej do 30m2 w odległości do
            20km od centrum Poznania.
          </S.MessageText>
          <S.MessageText>Wymagania:</S.MessageText>
          <S.Ul>
            <S.StyledLi>
              dygestorium oraz meble laboratoryjne w ilości adekwatnej do powierzchni,
            </S.StyledLi>
            <S.StyledLi>
              30m<sup>2</sup>
            </S.StyledLi>
            <S.StyledLi>budynek z windą,</S.StyledLi>
            <S.StyledLi>powierzchnia dostępna od tego tygodnia.</S.StyledLi>
          </S.Ul>
          <S.MessageText>
            Planujemy wynajem długoterminowy t.j. zawarcie umowy na okres 12-15 miesięcy z
            możliwością wydłużenia okresu najmu.
          </S.MessageText>
          <S.MessageText>
            Ze względu na konieczność wynajmu powierzchni w ramach rozpoczętej realizacji projektu
            proszę przesłać oferty w terminie do 3 dni od wysłania przez nas zapytania.
          </S.MessageText>
          <S.MessageText>
            Poszukujemy praktycznej powierzchni laboratoryjnej na terenie Poznania i okolic, stąd
            jesteśmy zainteresowani wynajęciem powierzchni spełniającej normy, umożliwiające nam
            prowadzenie badań B+R.
          </S.MessageText>
          <S.MessageText>
            W ofercie proszę o wskazanie jakie są koszty dodatkowe związane z najmem t.j. koszt
            internetu, miejsca parkingowego, energii elektrycznej, ochrony i innych, które będziemy
            zobowiązani dodatkowo ponosić.
          </S.MessageText>
          <S.MessageText>Podczas oceny ofert pod uwagę będziemy brali:</S.MessageText>
          <S.Ul>
            <S.StyledLi>zgodność przedmiotu oferty z naszymi potrzebami oraz cenę.</S.StyledLi>
          </S.Ul>
          <S.MessageText>Preferujemy rozliczenia comiesięczne.</S.MessageText>
          <S.MessageText>W przypadku pytań pozostaję do Państwa dyspozycji.</S.MessageText>
          <br />
          <S.MessageText>Z poważaniem,</S.MessageText>
          <S.MessageText>Prezes Zarządu MonitLab Sp. z o.o.</S.MessageText>
          <S.MessageText>Paulina Zalewska</S.MessageText>
        </S.MessageBody>
        <S.Row>
          <Till isDisplayed header='Składanie ofert'>
            <S.TillText>
              Oferty można składać w formie elektronicznej do: 2020-05-20 do godziny 17:00 na adres{' '}
              <S.StyledA href={'mailto:biuro@monitlab.pl'}>biuro@monitlab.pl</S.StyledA>
            </S.TillText>
          </Till>
          <Till isDisplayed header='Wyniki postępowania'>
            <S.TillText>
              2020-05-26 W skutek przeprowadzonego postępowania ofertowego, za najkorzystniejszą
              została uznana oferta Fundacja Uniwersytetu im. Adama Mickiewicza w Poznaniu PPNT.
            </S.TillText>
          </Till>
        </S.Row>
      </S.Wrapper>
    </WidePhotoSectionWrapper>
  );
};

export default LaboratoryRequest;
