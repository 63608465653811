import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 20rem;
  box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
  border-radius: 12px;
  background-color: #fff;
  margin: .5rem;
  padding: 2rem;
  box-sizing: border-box;
`;

export const Mock = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 350px;
`;

export const Header = styled.h5`
  margin: 0 0 2rem;
  text-align: left;
  font-family: Poppins;
  font-size: 1.5rem;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;
