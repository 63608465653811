import {
  FaMobileAlt,
  FaRegEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaLinkedin,
  FaPinterest,
  FaTwitter,
} from 'react-icons/fa'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

export const Wrapper = styled.footer`
  /* width: 100vw; */
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  color: ${({ theme: { white } }) => white};
  font-family: Poppins;
  display: flex;
  flex-direction: column;
`

export const Body = styled.div`
  max-width: 1350px;
  width: 100%;
  margin: 0 auto 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem 1rem 0;
  @media (min-width: 600px) {
    padding: 2rem 2rem 0;
  }
  box-sizing: border-box;
`

export const WideColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 286px;
  flex-grow: 4;
  margin: 0.5rem 0.5rem 2rem;
  padding: 0 1rem;
  box-sizing: border-box;
`

export const NarrowColumn = styled(WideColumn)`
  flex-basis: 162px;
  flex-grow: 3;
`

export const Header = styled.h4`
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 0.5rem;
`

export const Spacer = styled.hr`
  width: 75px;
  background-color: ${({ theme: { white } }) => white};
  margin: 0 auto 1rem 0;
  height: 1px;
`

export const ContactItem = styled.a`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 0;
  align-items: center;
`

export const ContactAddress = styled(ContactItem)`
  display: none;
  @media (min-width: 700px) {
    display: flex;
  }
`

export const Label = styled.p`
  margin: 0.5rem 0;
  color: ${({ theme: { white } }) => white};
  font-family: Roboto;
  font-size: 1rem;
  line-height: 160%;
`

const IconProps = css`
  margin: 0 0.75rem 0 0;
  width: 1.5rem;
  height: 1.5rem;
`

export const MobileIcon = styled(FaMobileAlt)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const MailIcon = styled(FaRegEnvelope)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const MapPin = styled(FaMapMarkerAlt)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const PartnersWrapper = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  margin: auto;
  box-sizing: border-box;
  background-color: ${({ theme: { mainBackground } }) => mainBackground};
`

export const PartnerIcon = styled.div`
  margin: 0 28px 12px;
  max-width: 148px;
  img {
    width: 100%;
    object-fit: contain;
  }
`

export const StyledLink = styled(Link)`
  margin: 0.5rem 0;
  color: ${({ theme: { white } }) => white};
  font-size: 1rem;
`

export const Description = styled.p`
  font-size: 1rem;
  line-height: 30px;
  margin: 0 0 1.5rem;
`

export const SubHeader = styled(Header)`
  font-size: 1rem;
  margin-top: 1.5rem;
`

export const MediaWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Icon = css`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.5rem;
  color: ${({ theme: { white } }) => white};
`

export const Twitter = styled(FaTwitter)`
  ${Icon}
`

export const Facebook = styled(FaFacebook)`
  ${Icon}
`

export const LinkedIn = styled(FaLinkedin)`
  ${Icon}
`

export const Pinterest = styled(FaPinterest)`
  ${Icon}
`
