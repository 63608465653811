import styled from 'styled-components'

export const Wrapper = styled.section<{ padding: string }>`
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
  padding: ${({ padding }) => padding};
`

export const Background = styled.div`
  width: 100%;
  min-height: calc(100vw * (578 / 1920));
  position: relative;
  display: flex;
  margin: 0 0 3rem;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
`

export const Veil = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background: ${({ theme: { mainBlue } }) => ` linear-gradient(-90deg, ${mainBlue} 0%, #fff 100%)`};
`

export const HeaderWrapper = styled.span`
  max-width: 1350px;
  padding: 0 1rem;
  z-index: 2;
  margin: auto;
  text-align: center;
  @media (min-width: 600px) {
    width: 100%;
    text-align: left;
    margin: auto auto 2rem;
    padding: 0 2rem;
  }
  display: flex;
`

export const Header = styled.h2`
  font-size: 2.5rem;
  margin: 1rem 0;
  @media (min-width: 600px) {
    margin: 0;
  }
  @media (min-width: 800px) {
    font-size: 4rem;
  }
  font-family: Poppins;
  color: ${({ theme: { mainRed } }) => mainRed};
  text-shadow: 2px 2px 4px ${({ theme: { mainBlue } }) => mainBlue};
`

export const BodyWrapper = styled.div`
  max-width: 1350px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`
