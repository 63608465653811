import styled from 'styled-components';

export const Button = styled.button<{
  styles?: string;
  colorTheme?: 'default' | 'save' | 'cancel' | 'delete';
}>`
  border-radius: 4px;
  padding: 2px;
  box-sizing: border-box;
  ${({ styles }) => styles};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  :disabled {
    filter: saturate(0.4);
    cursor: default;
  }
  width: fit-content;
`;

export const LabelWrapper = styled.div<{ styles?: string; isHover?: boolean; disabled?: boolean; colorTheme?: 'default' | 'save' | 'cancel' | 'delete'; }>`
  background: ${({ colorTheme }) =>
    ` ${colorTheme === 'save' ? '#0C4190' : colorTheme === 'cancel' ? '#900C3F' : colorTheme === 'delete' ? '#FFEFEE' : '#EBF5FF'
    }`};
  transition: 250ms;
  border-radius: 4px;
  padding: 1rem;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  ${({ styles }) => styles};
  min-width: 100px;
  &:hover {
    box-shadow: 0px 0px 7px ${({ colorTheme, disabled }) => disabled ? '' : colorTheme === 'save' ? '#0C4190' : colorTheme === 'cancel' ? '#900C3F' : colorTheme === 'delete' ? '#FFEFEE' : '#EBF5FF' };
  }
`;

export const Label = styled.div<{
  styles?: string;
  isHover?: boolean;
  disabled?: boolean;
  colorTheme?: 'default' | 'save' | 'cancel' | 'delete';
}>`
  background: ${({ colorTheme }) =>
    ` ${colorTheme === 'save' ? '#00305C' : colorTheme === 'cancel' ? '#900C3F' : colorTheme === 'delete' ? '#C60A14' : '#00305C'
    }`};
  transition: color 250ms;
  font-family: Poppins;
  font-size: 1rem;
  background-clip: text;
  -webkit-background-clip: text;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color:  ${({ colorTheme }) =>
    ` ${colorTheme === 'save' ? 'white' : colorTheme === 'cancel' ? 'white' : colorTheme === 'delete' ? '#C60A14' : '#00305C'
    }`};
`;

export const IconWrapper = styled.div<{ colorTheme?: 'default' | 'save' | 'cancel' | 'delete';}>`
display: flex;
padding-right: 6px;
color: ${({ colorTheme }) =>
    ` ${colorTheme === 'save' ? '#00305C' : colorTheme === 'cancel' ? '#ffdab9' : colorTheme === 'delete' ? '#C60A14' : '#00305C'
    }`};`


