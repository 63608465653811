import styled from 'styled-components'

export const Wrapper = styled.div<{isLoggedIn: boolean}>`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  min-height: 100vh;
  @media (min-width: 1170px){
    flex-direction: ${({ isLoggedIn})=> isLoggedIn ? 'row' : 'column'};
  }
`

export const Main = styled.main`
  width: 100%;
  /* min-height: calc(100vh - 217px); */
  background-color: ${({theme: { mainBackground }}) => mainBackground};
  flex-grow: 1;
  overflow-y: scroll;
  @media (min-width:570px){
    /* min-height: calc(100vh - 180px); */
  }
`
export const LougoutButtonContainer = styled.div`
display: flex;
justify-content: center;
width: 100%;
padding: 15px;`
