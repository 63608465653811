import { ChangeEvent, useState } from 'react'
import { ISubmission, SUBMISSION_STATUS } from '../../../utils/types.d'
import { statusOptions } from '../SubmissionsList/SubmissionsList/SubmissionsList'
import { useSelector } from 'react-redux'
import { usersId, useUserStatus } from '../../../redux/auth/selectors'
import Select from 'react-select'
import ResultsGenerator from '../../ResultsGenerator/ResultsGenerator'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { getPDFValues } from '../../../utils/submissions'
import * as S from './Submission.styles'

const RequestForm = ({
  localizationOptions,
  editData,
  data,
  noLocationError,
}: {
  data: ISubmission
  noLocationError: boolean
  localizationOptions: { value: any; label: any }[]
  editData: (field: string, value: any) => void
}) => {
  const {
    id,
    requestName,
    isPriority,
    owner,
    locationId,
    ownerId,
    status = SUBMISSION_STATUS.AWAITING,
  } = data
  const { isAdmin } = useSelector(useUserStatus)
  const loggedInUserId = useSelector(usersId)
  const isOwner = ownerId ? ownerId === loggedInUserId : true
  const isNew = id === undefined
  const [isDisabled, setIsDisabled] = useState<boolean>(!(isNew || isOwner))
  const isEditionUnavailable = isOwner
    ? [SUBMISSION_STATUS.AWAITING, SUBMISSION_STATUS.IN_DELIVERY].includes(status)
    : false
  const resultsProps = getPDFValues(data)

  const dropdownColor = noLocationError ? 'white' : '#0C4190'
  const textColor = noLocationError ? 'red' : 'white'
  const borderColor = noLocationError ? '2px solid red' : 'none'

  const styles = {
    control: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
      backgroundColor: `${dropdownColor}`,
      fontSize: '1rem',
      fontFamily: 'Poppins',
      borderRadius: '4px',
      borderStyle: '',
      padding: '0 0.5rem 0 0.5rem',
      cursor: 'pointer',
      border: `${borderColor}`,
      boxShadow: 'none',
      '&:hover': 'none',
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: 'white',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      color: 'black',
      backgroundColor: 'white',
      fontSize: '1rem',
      padding: '0.25rem 0.5rem 0.25rem 0.5rem',
      cursor: 'pointer',
      '&:hover': { backgroundColor: '#F5F5F5' },
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      color: 'black',
      backgroundColor: 'white',
      fontSize: '1rem',
      padding: '0.25rem 0.5rem 0.25rem 0.5rem',
      borderRadius: '0px',
      borderStyle: 'none',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: `${textColor}`,
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      color: `${textColor}`,
    }),
    input: (provided: any, state: any) => {
      return {
        ...provided,
        color: 'white',
      }
    },
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,
        color: 'white',
        '&:hover': { color: '#F5F5F5' },
      }
    },
  }

  const locationValue = locationId && localizationOptions.find((l) => l.value === locationId)
  return (
    <>
      <S.SamplesHeader>Dane zlecenia:</S.SamplesHeader>
      {isAdmin && !isNew && (
        <PDFDownloadLink document={<ResultsGenerator {...resultsProps} />} fileName="wyniki.pdf">
          {({ loading }) => (loading ? 'Wczytywanie dokumentu...' : 'Pobierz PDF do zweryfikowania')}
        </PDFDownloadLink>
      )}
      {!isAdmin && !isNew && !isEditionUnavailable && (
        <S.UnlockButton type={'button'} onClick={() => setIsDisabled(!isDisabled)}>
          Edytuj formularz
        </S.UnlockButton>
      )}
      <S.Wrapper>
        <S.Row>
          <S.InputLabel>Identyfikator:</S.InputLabel>
          <S.InputWrapper>
            <S.SmallInput
              required={isNew}
              disabled={isDisabled}
              value={requestName}
              onChange={(e: ChangeEvent<{ value: string }>) => editData('requestName', e.target.value)}
            />
          </S.InputWrapper>
        </S.Row>
        {!isOwner && owner && (
          <S.Row>
            <S.InputLabel>Zleceniodawca:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput disabled value={`${owner.name} ${owner.surname}`} />
            </S.InputWrapper>
          </S.Row>
        )}
        {
          <S.Row>
            <S.InputLabel>Lokalizacja:</S.InputLabel>
            <S.InputWrapper>
              <Select
                value={locationValue}
                options={localizationOptions}
                onChange={(e: any) => editData('locationId', e.value)}
                styles={styles}
                placeholder='Wybierz'
              />
            </S.InputWrapper>
          </S.Row>
        }
        <S.Row>
          <S.InputLabel>Priotytet:</S.InputLabel>
          <S.InputWrapper isTransparent isRadio={true}>
            <S.RadioContainer>
              <S.RadioWrapper>
                <S.Checkbox
                  id={'normalny'}
                  required={false}
                  disabled={false}
                  type={'radio'}
                  checked={!isPriority}
                  onChange={() => editData('isPriority', false)}
                />
                <S.RadioLabel onClick={() => editData('isPriority', false)}>Normalny</S.RadioLabel>
              </S.RadioWrapper>
              <S.RadioWrapper>
                <S.Checkbox
                  id={'priorytet'}
                  required={false}
                  disabled={false}
                  type={'radio'}
                  checked={isPriority}
                  onChange={() => editData('isPriority', true)}
                />
                <S.RadioLabel onClick={() => editData('isPriority', true)}>Wysoki</S.RadioLabel>
              </S.RadioWrapper>
            </S.RadioContainer>
          </S.InputWrapper>
        </S.Row>
        {!isOwner && (
          <S.Row>
            <S.InputLabel>Status:</S.InputLabel>
            <S.InputWrapper>
              <Select placeholder="Status" options={statusOptions} />
            </S.InputWrapper>
          </S.Row>
        )}
      </S.Wrapper>
    </>
  )
}

export default RequestForm
