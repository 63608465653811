import News from '../../../public/rework/news.jpeg';
import * as S from './WidePhotoSectionWrapper.styles';

interface IWidePhotoSectionWrapper {
  children: JSX.Element | JSX.Element[];
  photo?: any;
  alt?: any;
  title: string;
  padding?: string
}

export default function WidePhotoSectionWrapper({
  children,
  title,
  alt = 'background image',
  photo = News,
  padding = '0'
}: IWidePhotoSectionWrapper) {
  return (
    <S.Wrapper padding={padding}>
      <S.Background>
        <S.Veil />
        <S.Image src={photo} alt={alt} />
        <S.HeaderWrapper>
          <S.Header dangerouslySetInnerHTML={{__html: title}} />
        </S.HeaderWrapper>
      </S.Background>
      <S.BodyWrapper>{children}</S.BodyWrapper>
    </S.Wrapper>
  );
}
