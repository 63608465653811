import Pipetting from '../../public/rework/pippeting.jpeg';
import * as S from './PictureAndListSection.styles';

interface IPictureAndListSection {
  image?: any;
  alt?: string;
  listItems?: string[];
  text?: string;
  title?: string;
  isLeft?: boolean;
  isContain?: boolean;
}

export default function PictureAndListSection({
  image = Pipetting,
  alt = 'pipetting',
  listItems,
  title,
  isLeft = true,
  text,
  isContain = false,
}: IPictureAndListSection) {
  return (
    <S.Wrapper>
      <S.BodyWrapper isLeft={isLeft}>
        <S.PhotoColumn isContain={isContain}>
          <S.Image isContain={isContain} src={image} alt={alt} />
        </S.PhotoColumn>
        <S.TextColumn>
          {title && <S.Header>{title}</S.Header>}
          {listItems && (
            <S.List>
              {listItems.map((item, index: number) => (
                <S.ListItem key={`lit_item_${index}`}>{item}</S.ListItem>
              ))}
            </S.List>
          )}
          {text && <S.Text>{text}</S.Text>}
        </S.TextColumn>
      </S.BodyWrapper>
    </S.Wrapper>
  );
}
