import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: center;
`;

export const Avatar = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  background-color: ${({ theme: { gradient2 } }) => gradient2};
  border-radius: 100%;
  margin-right: 1rem;
  img {
    object-fit: cover;
  }
  `;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TextRow = styled.p`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1rem;
  line-height: 140%;
  margin-right: auto;
  font-weight: 500;
`;

export const Button = styled.button`
    color: ${({ theme: { mainBlue } }) => mainBlue};
    border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
    border-radius: 4px;
  font-family: Poppins;
  padding: .125rem;
  font-size: .865rem;
  line-height: 140%;
  font-weight: 500;
  transition: 250ms;
  margin: .25rem 0 0;
  :hover {
    color: ${({ theme: { mainRed } }) => mainRed};
    border-color: ${({ theme: { mainRed } }) => mainRed};
  }
`
