import circleThree from '../../public/assets/circleThree.png'
import PictureAndListSection from '../PictureAndListSection/PictureAndListSection'
import * as S from './PersonalizedMedicine.styles'

const PersonalizedMedicine = () => (
  <S.Wrapper>
    <PictureAndListSection
      isLeft={false}
      isContain
      alt={'three advantages for a patient'}
      image={circleThree}
      text={`Medycyna personalizowana to innowacyjna metoda leczenia, dająca pacjentom i\u00A0lekarzom możliwość całkowicie nowego, indywidualnego podejścia do leczenia farmakologicznego, co pozwala na wydajniejsze i\u00A0bezpieczniejsze stosowanie leków. Korzyści płynące z\u00A0takiego postępowania gwarantują optymalne wykorzystanie tradycyjnych metod terapeutycznych w\u00A0leczeniu chorób, a\u00A0także zastosowanie terapii i\u00A0leków nowej generacji. Jest to całkowicie nowe podejście zrywające z\u00A0dotychczas często stosowaną praktyką leczenia wszystkich pacjentów z\u00A0danym schorzeniem tą samą metodą lub lekiem i\u00A0wystandaryzowaną dawką. 
        Obecnie medycyna personalizowana ma największe zastosowanie w\u00A0onkologii, ale rozwija się dynamicznie także w\u00A0innych dziedzinach, będąc niewątpliwie przyszłością medycyny.`}
    />
  </S.Wrapper>
)

export default PersonalizedMedicine
