import styled from 'styled-components'
import { CommonInput } from '../ContactSection/ContactSection.styles'

export const Wrapper = styled.div`
  max-width: 1500px;
  margin: 2rem auto;
  padding: 2rem;
  height: calc(100vh - 250px);
  background-color: ${({ theme: { titaniumWhite } }) => titaniumWhite};
`
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 500px;
  margin: auto;
`

export const Input = styled.input`
  ${CommonInput}
  background-color: #fff;
  box-shadow: 5px 5px 15px ${({ theme: { mainBlue } }) => mainBlue}55;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  :placeholder {
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
`

export const LoginHeader = styled.div`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  margin: 0 auto 2rem;
  font-size: 2rem;
  max-width: 500px;
  @media (min-width: 500px) {
    font-size: 3rem;
  }
  @media (min-width: 1024px) {
    margin: 0 auto 2rem;
  }
`

export const ErrorMessage = styled.p<{ isDisplayed: boolean }>`
  width: 100%;
  max-width: 500px;
  text-align: center;
  opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
  transition: opacity 300ms;
  color: ${({ theme: { mainRed } }) => mainRed};
  background-color: ${({ theme: { titaniumWhite } }) => titaniumWhite};
  font-family: Poppins;
  margin: 1rem auto;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1.5rem;
  min-height: 43px;
`

export const AdminContact = styled.a<{ isDisplayed: boolean }>`
  color: ${({ theme: { mainRed } }) => mainRed};
  background-color: ${({ theme: { titaniumWhite } }) => titaniumWhite};
  font-family: Poppins;
  text-decoration: underline;
  opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
  transition: opacity 300ms;
  margin: 0 auto;
  padding: 0.5rem;
  display: block;
  font-size: 1rem;
  width: 100%;
  max-width: 500px;
  text-align: center;
`
export const CommonButtonsWrapper = styled.div`
display: flex;
width: 100%;
justify-content: space-around;
`
export const LoginHeaderWrapper = styled.div`
display: flex;
width: 100;
justify-content: center;`