import styled from 'styled-components';
import { View } from '@react-pdf/renderer';

export const Wrapper = styled(View)`
  color: #0d3e8e;
	font-weight: 500;
  padding: 3%;`

export const HeaderLogoWrapper = styled(View)`
`
export const HeaderLogo = styled(View)`
  height: 48px;
  width: 310px;
  background-image: url('/rework/logo-big.png');
  -webkit-print-color-adjust: exact;
`
export const BodySection = styled(View)``

export const LogoWrapper = styled(View)`
	display: flex;
	position: relative;
  justify-content: center;`

export const LogoSmall = styled(View)`
  height: 114px;
  width: 112px;
  background-image: url(/rework/logo-small.png);
  -webkit-print-color-adjust: exact;
  background-repeat: no-repeat;
  z-index: 1;
`

export const FooterRow = styled(View)`
	display: flex;
	padding-bottom: 20px;
	align-items: center;
`

export const FooterIcon = styled(View)`
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  -webkit-print-color-adjust: exact;
  background-position: center;
  margin-right: 20px;
`
