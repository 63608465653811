import * as React from 'react';
import { useEffect } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ILocation } from '../Dashboard/LocationsTable/LocationsTable';
import { useDispatch } from 'react-redux'
import { assignUserLocations } from '../../redux/data/actions';
import { getFormattedInitialLocations } from '../../utils/FormatInitialLocations';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MSCProps {
  locations: ILocation[]
  userId: string,
  initialLocations: any
}

const getStyles = (name: string, personName: string[] = [], theme: Theme) => ({
  fontWeight:
    personName.indexOf(name) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  display: 'flex',
  paddingLeft: '16px',
  justifyContent: 'flex-start',
  fontSize: '20px',
  padding: '4px 0 4px 16px',
  margin: `4px`,
  fontFamily: 'Poppins'
})

const MultipleSelectCheckmarks = ({ locations, userId, initialLocations = [] }: MSCProps) => {
  const theme = useTheme();
  const [selectedLocations, setSelectedLocations] = React.useState<string[]>(getFormattedInitialLocations(initialLocations));

  useEffect(() => {
    if (initialLocations && initialLocations.length > 0) {
      setSelectedLocations(getFormattedInitialLocations(initialLocations))
    }
  }, [initialLocations])

  const dispatch = useDispatch()
  const formattedOptions = locations.map(({ id, name, secondaryName }) => ({
    id,
    formattedName: `${name} ${secondaryName}`
  }))


  const handleChange = (event: SelectChangeEvent<typeof selectedLocations>) => {
    const {
      target: { value },
    } = event;
    setSelectedLocations(typeof value === 'string' ? value.split(',') : value);
  };

  const handleOnClose = () => {
    const selectedIds = selectedLocations.map(selectedLocation => {
      const maybeFormattedOptions = formattedOptions.find(opt => opt.formattedName === selectedLocation);
      return maybeFormattedOptions ? maybeFormattedOptions.id : null
    })
    dispatch(assignUserLocations({ userId, locationsIds: selectedIds.filter(i => !!i) }))
  }

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={selectedLocations}
          onChange={handleChange}
          onClose={handleOnClose}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  style={{ fontSize: "16px" }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {locations && locations.map(({ name, secondaryName }: ILocation) => {
            const formattedValue = `${name} ${secondaryName}`
            return (
              <MenuItem
                key={formattedValue}
                value={formattedValue}
                style={getStyles(name, initialLocations, theme)}
              >
                {formattedValue}
              </MenuItem>)
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultipleSelectCheckmarks;
