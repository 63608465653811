import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
`;

export const BodyWrapper = styled.div`
  max-width: 1350px;
  width: 100%;
  padding: 0;
  margin: 0 auto;
  display: flex;
  @media (min-width: 600px) {
    padding: 0 2rem 3rem;
  }
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }
  box-sizing: border-box;
`;

export const Div800 = styled.aside`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 0 4rem;
  @media (min-width: 1024px) {
    margin: 0;
    margin-left: auto;
    min-width: 450px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 4px 0 1px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  background-color: #fff;
  border-radius: 8px 8px 0 0;
`;

export const LabelBottom = styled.img`
  flex-grow: 1;
  margin-right: 3px;
  margin-left: 1px;
  transform: scaleX(-1);
`;

export const Header = styled.h1`
  font-weight: 500;
  font-family: Poppins;
  font-size: 40px;
  line-height: 48px;
  ${({ theme: { mainBlue } }) => mainBlue};
  margin: 0 0 2rem;
`;

export const Description = styled.p`
  font-family: Poppins;
  ${({ theme: { mainBlue } }) => mainBlue}
  font-size: 18px;
  line-height: 30px;
  white-space: pre-line;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 464px;
`;

export const TillsWrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 2;
  width: 100%;
  @media (min-width: 600px) {
    margin: 0 0 5rem;
  }
  @media (min-width: 1024px) {
    min-width: 500px;
  }
`;

export const TillWrapper = styled.article<{ isWide?: boolean }>`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin: 1rem;
  background-color: #fff;
  ${({ isWide }) =>
      !isWide &&
      `width: 100%;`};
  @media (min-width: 532px) {
    flex-basis: 200px;
    width: unset;
    max-width: 436px;
    ${({ isWide }) =>
      isWide &&
      `width: 100%;
    flex-grow: unset;
    flex-basis: unset;`};
    width: fit-content;
  }
`;

export const Icon = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const SafetyWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #7dd6f6 0%, #797ef6 100%);
  margin: 0 auto 0.5rem;
  border-radius: 100%;
`;

export const EfficacyWrapper = styled(SafetyWrapper)`
  background: linear-gradient(180deg, #f96969 0%, #eb58b9 100%);
`;

export const MoneyWrapper = styled(SafetyWrapper)`
  background: linear-gradient(180deg, #26ad8e 0%, #5bc199 100%);
`;

export const Label = styled.label`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1rem;
  line-height: 160%;
  text-align: center;
`;
