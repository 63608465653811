import * as S from './Till.styles';

interface Till {
  children?: JSX.Element;
  header?: string;
  isDisplayed: boolean;
}

export default function TillComponent ({ isDisplayed, header, children }: Till) {
  if (!isDisplayed) {
    return <S.Mock />;
  }
  return (
    <S.Wrapper>
      <S.Header>{header}</S.Header>
      {children}
    </S.Wrapper>
  );
};
