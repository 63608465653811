import * as A from './actionTypes'
import * as api from '../../api/dataAPI'
import { history } from '../../App'
import { IAssignUserLocationsPayload, ICreateUser, INewLocationPayload, IUpdateLocationPayload, IUpdateUserStatus } from './types'
import { ISubmission, ISubmissionBasic } from '../../utils/types'
import { toast } from 'react-toastify'

export function getSubmissions() {
  return async function (dispatch: any) {
    dispatch({ type: A.GET_ALL_SUBMISSIONS })
    return api.getSubmissions().then(
      (response: any) => {
        dispatch({ type: A.GET_ALL_SUBMISSIONS_SUCCESS, data: response.data })
      },
      (error: any) => {
        dispatch({ type: A.GET_ALL_SUBMISSIONS_FAIL })
        console.log('An error occurred.', error)
      },
    )
  }
}

export function resetSubmission() {
  return {
    type: A.GET_SUBMISSION_SUCCESS,
    data: undefined,
  }
}

export function getSubmission(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.GET_SUBMISSION })
    return api.getSubmission(id).then(
      (response: any) => {
        dispatch({ type: A.GET_SUBMISSION_SUCCESS, data: response.data })
      },
      (error: any) => {
        dispatch({ type: A.GET_SUBMISSION_FAIL })
        console.log('An error occurred.', error)
      },
    )
  }
}

export function createSubmission(payload: ISubmissionBasic) {
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_SUBMISSION })
    return api.createSubmission(payload).then(
      (response: any) => {
        dispatch({ type: A.CREATE_SUBMISSION_SUCCESS, data: response.data })
        toast.success('Zlecenie utworzone')
        history.push('/dashboard/submissions')
      },
      (error: any) => {
        dispatch({ type: A.CREATE_SUBMISSION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function updateSubmission(payload: ISubmission) {
  return async function (dispatch: any) {
    dispatch({ type: A.UPDATE_SUBMISSION })
    return api.updateSubmission(payload).then(
      (response: any) => {
        dispatch({ type: A.UPDATE_SUBMISSION_SUCCESS, data: response.data })
        toast.success('Pomyślnie zaktualizowano')
        history.push('/dashboard/submissions')
      },
      (error: any) => {
        dispatch({ type: A.UPDATE_SUBMISSION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function deleteSubmission(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.DELETE_SUBMISSION })
    return api.deleteSubmission(id).then(
      (response: any) => {
        dispatch({ type: A.DELETE_SUBMISSION_SUCCESS, data: response.data })
        toast.success('Analiza została usunięta')
        history.push('/dashboard/submissions')
      },
      (error: any) => {
        dispatch({ type: A.DELETE_SUBMISSION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function getLocations() {
  return async function (dispatch: any) {
    dispatch({ type: A.GET_ALL_LOCATIONS })
    return api.getLocations().then(
      (response: any) => {
        dispatch({ type: A.GET_ALL_LOCATIONS_SUCCESS, data: response.data })
      },
      (error: any) => {
        dispatch({ type: A.GET_ALL_LOCATIONS_FAIL })
        console.log('An error occurred.', error)
      },
    )
  }
}

export function deleteLocation(locationId: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.DELETE_LOCATION })
    return api.deleteLocation(locationId).then(
      (response: any) => {
        dispatch({ type: A.DELETE_LOCATION_SUCCESS, data: locationId })
        toast.success('Lokalizacja została usunięta')
      },
      (error: any) => {
        dispatch({ type: A.DELETE_LOCATION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function getLocation(locationId: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.GET_LOCATION })
    return api.getLocation(locationId).then(
      (response: any) => {
        dispatch({ type: A.GET_LOCATION_SUCCESS, data: response.data })
      },
      (error: any) => {
        dispatch({ type: A.GET_LOCATION_FAIL })
        console.log('An error occurred.', error)
      },
    )
  }
}

export const setCreateLocationErrorMessage = (errorMessage: string) => ({
  type: A.SET_LOCATION_ERROR_MESSAGE,
  errorMessage
  })

export function createLocation(payload: INewLocationPayload) {
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_LOCATION })
    return api.createLocation(payload).then(
      (response: any) => {
        dispatch({ type: A.CREATE_LOCATION_SUCCESS, data: response.data })
        toast.success('Placówka została utworzona')
        history.push('/dashboard/locations')
      },
      (error: any) => {
        const errorMessage = error.response ? error.response.data : ''
        dispatch({ type: A.CREATE_LOCATION_FAIL })
        dispatch(setCreateLocationErrorMessage(errorMessage))
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function updateLocation(payload: IUpdateLocationPayload) {
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_LOCATION })
    return api.updateLocation(payload).then(
      (response: any) => {
        dispatch({ type: A.CREATE_LOCATION_SUCCESS, data: response.data })
        toast.success('Pomyślnie zaktualizowano')
      },
      (error: any) => {
        dispatch({ type: A.CREATE_LOCATION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function assignUserLocations(payload: IAssignUserLocationsPayload) {
  return async function (dispatch: any) {
    dispatch({ type: A.ASSIGN_USER_LOCATION })
    return api.assigUserLocations(payload).then(
      (response: any) => {
        dispatch({
          type: A.ASSIGN_USER_LOCATION_SUCCESS, data: {
            locations: response.data,
            userId: payload.userId
          }
        })
        toast.success('Placówki pomyślnie przypisane')
      },
      (error: any) => {
        dispatch({ type: A.ASSIGN_USER_LOCATION_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function getUsers() {
  return async function (dispatch: any) {
    dispatch({ type: A.GET_ALL_USERS })
    return api.getUsers().then(
      (response: any) => {
        dispatch({ type: A.GET_ALL_USERS_SUCCESS, data: response.data })
      },
      (error: any) => {
        dispatch({ type: A.GET_ALL_USERS_FAIL })
        console.log('An error occurred.', error)
      },
    )
  }
}

export const setCreateUserErrorMessage = (errorMessage: string) => ({
  type: A.SET_USER_ERROR_MESSAGE,
  errorMessage
  })

export function createUser(payload: ICreateUser){
  return async function (dispatch: any) {
    dispatch({ type: A.CREATE_USER })
    return api.createUser(payload).then(
      (response: any) => {
        dispatch({ type: A.CREATE_USER_SUCCESS, data: response.data })
        toast.success('Użytkownik stworzony pomyślnie')
        history.push('/dashboard/users')
      },
      (error: any) => {
        const errorMessage = error.response ? error.response.data : ''
        dispatch({ type: A.CREATE_USER_FAIL })
        dispatch(setCreateUserErrorMessage(errorMessage))
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function deleteUser(id: string) {
  return async function (dispatch: any) {
    dispatch({ type: A.DELETE_USER })
    return api.deleteUser({ id }).then(
      (response: any) => {
        dispatch({ type: A.DELETE_USER_SUCCESS, data: response.data })
        toast.success('Użytkownik usunięty pomyślnie')
        history.push('/dashboard/users')
      },
      (error: any) => {
        dispatch({ type: A.DELETE_USER_FAILED })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}

export function updateUsersStatus(payload: IUpdateUserStatus) {
  return async function (dispatch: any) {
    dispatch({ type: A.CHANGE_USER_STATUS })
    return api.updateUsersStatus(payload).then(
      (response: any) => {
        dispatch({ type: A.CHANGE_USER_STATUS_SUCCESS, data: response.data })
        toast.success("Status zaktualizowany")
      },
      (error: any) => {
        dispatch({ type: A.CHANGE_USER_STATUS_FAIL })
        toast.error('Wystąpił błąd. Spróbuj ponownie')
        console.log('An error occurred.', error)
      },
    )
  }
}
