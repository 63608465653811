import { logout } from '../../../redux/auth/actions'
import { useDispatch } from 'react-redux'
import * as S from '../DashboardPanel/DashboardPanel.styles'

const LogoutButton = () => {
    const dispatch = useDispatch()
    return (
        <S.ButtonContainer onClick={() => dispatch(logout())}>
            <S.ButtonIconContainer>
                <S.LogoutIcon />
            </S.ButtonIconContainer>
            <S.Button>Wyloguj się</S.Button>
        </S.ButtonContainer>
    )
}

export default LogoutButton
