import { Link } from 'react-router-dom'
import * as S from './DashboardWrapper.styles';

export default function DashboardWrapper({
  header,
  href,
  linkText,
  children,
  icon,
}: {
  header: string;
  href?: string;
  icon?: any;
  linkText?: string;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.Header>{header}</S.Header>
        {href && (
          <Link to={href}>
            <S.Link>
              <S.IconContainer>
                {icon}
              </S.IconContainer>
              {linkText}
            </S.Link>
          </Link>
        )}
      </S.HeaderWrapper>
      <S.Body>{children}</S.Body>
    </S.Wrapper>
  );
}
