import HeaderSection from '../HeaderSection/HeaderSection';
import Team from '../../public/rework/team.jpeg';
import Roteur from '../../public/rework/roteur.jpeg';
// import Files from '../../public/rework/files.jpeg';
import Yellow from '../../public/rework/yellow.jpeg';
import PictureAndListSection from '../PictureAndListSection/PictureAndListSection';
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper';
import TeamImg from '../../public/rework/mockTeam.jpeg'
import * as S from './AboutUsSection.styles';

export default function AboutUsSection() {
  return (
    <>
      <HeaderSection
        backgroundSrc={Roteur}
        header={'Kim jesteśmy'}
        description={`MonitLab Sp. z\u00A0o.o. - Spółka założona na początku 2020\u00A0r. na potrzeby realizacji projektu B+R współfinansowanego ze środków Europejskiego Funduszu Rozwoju Regionalnego w\u00A0ramach Programu Operacyjnego Innowacyjna Gospodarka.`}
      />
      <div style={{ margin: '0 18px' }}>
        <PictureAndListSection
          title={'O nas'}
          // image={Files}
          image={Yellow}
          alt={'files photo'}
          listItems={[
            `Głównym celem spółki jest realizacja projektów B+R, oraz rozwój w\u00A0kierunku innych metod analitycznych oznaczania leków w\u00A0płynach biologicznych u\u00A0ludzi z\u00A0możliwością ich szerokiego zastosowania w\u00A0TDM.`,
            `W marcu 2020\u00A0r. MonitLab rozpoczął pracę nad projektem współfinansowanym z\u00A0Europejskiego Funduszu Rozwoju Regionalnego w\u00A0ramach programu Operacyjnego Innowacyjna Gospodarka “Opracowanie metody HPLC oznaczania poziomów posakonazolu w\u00A0osoczu ludzkim z\u00A0przeznaczeniem do terapeutycznego monitorowania leku w\u00A0celu poprawy bezpieczeństwa terapii grzybic układowych u\u00A0pacjentów hematoonkologicznych”.`,
          ]}
        />
      </div>
      <WidePhotoSectionWrapper
        padding={'6rem 0 0'}
        photo={Team}
        alt={'team photo'}
        title={'Zespół'}
      >
        <S.TeamPhoto>
          <img src={TeamImg} alt={'lab team'} />
        </S.TeamPhoto>
        <S.WhiteBackgroundText>
          {`Serce zespołu stanowią naukowcy z\u00A0wieloletnim doświadczeniem w\u00A0pracy analitycznej, bogatym dorobkiem naukowym w\u00A0dziedzinie farmacji i\u00A0medycyny.`}
        </S.WhiteBackgroundText>
      </WidePhotoSectionWrapper>
    </>
  );
}
