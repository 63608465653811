import { render } from 'react-dom'
import configureStore from './redux/store'
import { checkLoggedIn } from './api/userAPI'
import { Provider } from 'react-redux'
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css'
import App from './App'

const rootEl = document.getElementById('root')

const renderApp = (preloadedState: any) => {
  const store = configureStore(preloadedState)
  render(
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <App />
      </Provider>
    </StyledEngineProvider>,
    rootEl,
  )
}

  ; (async () => renderApp(await checkLoggedIn()))()
