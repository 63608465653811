import { ILocation } from '../../components/Dashboard/LocationsTable/LocationsTable'
import { sortUsersByEmail } from '../../utils/sorters'
import { getSortedSubmissions } from '../../utils/submissions'
import * as A from './actionTypes'
import { DataReducerState } from './types'

export const initialState: DataReducerState = {
  isFetchingData: false,
  isLoading: false,
  submissions: [],
  locations: [],
  users: [],
  createUserErrorMessage: '',
  createLocationErrorMessage: '',
}

const authReducer = (state = initialState, action: any): DataReducerState => {
  switch (action.type) {
    case A.GET_ALL_SUBMISSIONS: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case A.GET_ALL_SUBMISSIONS_FAIL: {
      return {
        ...state,
        isFetchingData: false,
      }
    }
    case A.GET_ALL_SUBMISSIONS_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        submissions: getSortedSubmissions(action.data),
      }
    }
    case A.GET_SUBMISSION: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case A.GET_SUBMISSION_FAIL: {
      return {
        ...state,
        isFetchingData: false,
      }
    }
    case A.GET_SUBMISSION_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        submission: action.data,
      }
    }
    case A.GET_ALL_LOCATIONS: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case A.GET_ALL_LOCATIONS_FAIL: {
      return {
        ...state,
        isFetchingData: false,
      }
    }
    case A.GET_ALL_LOCATIONS_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        locations: action.data,
      }
    }
    case A.CREATE_LOCATION: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.CREATE_LOCATION_FAIL: {
      return {
        ...state,
        isLoading: false,
        createLocationErrorMessage: action.errorMessage
      }
    }
    case A.CREATE_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        locations: [...state.locations, action.data],
      }
    }
    case A.GET_LOCATION: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case A.GET_LOCATION_FAIL: {
      return {
        ...state,
        isFetchingData: false,
      }
    }
    case A.GET_LOCATION_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        location: action.data
      }
    }
    case A.DELETE_LOCATION: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.DELETE_LOCATION_FAIL: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case A.DELETE_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        locations: state.locations.filter(({ id }: ILocation) => id !== action.data),
      }
    }
    case A.GET_ALL_USERS: {
      return {
        ...state,
        isFetchingData: true,
      }
    }
    case A.GET_ALL_USERS_FAIL: {
      return {
        ...state,
        isFetchingData: false,
      }
    }
    case A.GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        isFetchingData: false,
        users: sortUsersByEmail(action.data),
      }
    }
    case A.CREATE_USER: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.CREATE_USER_FAIL: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case A.CREATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: sortUsersByEmail([...state.users, action.data]),
      }
    }
    case A.CHANGE_USER_STATUS: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.CHANGE_USER_STATUS_FAIL: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case A.CHANGE_USER_STATUS_SUCCESS: {
      const { id, isActive } = action.data
      const otherUsers = state.users.filter(({ id: userId }) => userId !== id)
      const currentUser = state.users.find(({ id: userId }) => userId === id)
      const users = currentUser ? [...otherUsers, { ...currentUser, isActive }] : otherUsers
      return {
        ...state,
        isLoading: false,
        users,
      }
    }
    case A.DELETE_SUBMISSION: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.DELETE_SUBMISSION_FAIL: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case A.DELETE_SUBMISSION_SUCCESS: {
      const submissionId = action.data
      const submissions = state.submissions.filter(({ id }) => id !== submissionId)
      return {
        ...state,
        isLoading: false,
        submissions,
      }
    }
    case A.DELETE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: sortUsersByEmail(action.data),
      }
    }
    case A.UPDATE_SUBMISSION_SUCCESS: {
      const { id: updatedId } = action.data
      const submissions = getSortedSubmissions([...state.submissions.filter(({ id }) => id !== updatedId), action.data])

      return {
        ...state,
        submissions
      }
    }

    case A.ASSIGN_USER_LOCATION_SUCCESS: {
      const { userId: selectedUserId, locations } = action.data
      const otherUsers = state.users.filter(({ id: userId }) => userId !== selectedUserId)
      const currentUser = state.users.find(({ id: userId }) => userId === selectedUserId)
      const users = currentUser ? [...otherUsers, { ...currentUser, userLocation: locations }] : otherUsers

      return {
        ...state,
        isLoading: false,
        users: sortUsersByEmail(users),
      }
    }
    case A.SET_USER_ERROR_MESSAGE: {
      return {
        ...state,
        createUserErrorMessage: action.errorMessage
      }
    }
    case A.SET_LOCATION_ERROR_MESSAGE: {
      return {
        ...state,
        createLocationErrorMessage: action.errorMessage
      }
    }
    default:
      return state
  }
}

export default authReducer
