import {
  INewLocationPayload,
  IUpdateLocationPayload,
  ICreateUser,
  IUpdateUserStatus,
  IDeleteUser,
  IAssignUserLocationsPayload,
} from '../redux/data/types'
import { ISubmission, ISubmissionBasic } from '../utils/types'
import instance from './axiosProvider'

export const getSubmissions = async () => await instance.get(`submissions/all`)

export const getSubmission = async (id: string) => await instance.get(`submissions`, { params: { id } })

export const deleteSubmission = async (id: string) => await instance.delete(`submissions`, { params: { id } })

export const createSubmission = async (payload: ISubmissionBasic) =>
  await instance.post(`submissions/new`, payload)

export const updateSubmission = async (payload: ISubmission) =>
  await instance.post(`submissions/update`, payload)

export const getLocations = async () => await instance.get(`locations`)

export const getUsers = async () => await instance.get(`users`)

export const createUser = async (payload: ICreateUser) => await instance.post(`users`, payload)

export const deleteUser = async ({ id }: IDeleteUser) => await instance.delete(`users/${id}`)

export const updateUsersStatus = async (payload: IUpdateUserStatus) => await instance.patch(`users`, payload)

export const getLocation = async (locationId: string) =>
  await instance.get(`locations/${locationId}`)

export const deleteLocation = async (locationId: string) =>
  await instance.delete(`locations/${locationId}`)

export const createLocation = async (payload: INewLocationPayload) =>
  await instance.post(`locations/new`, payload)

export const updateLocation = async (payload: IUpdateLocationPayload) =>
  await instance.post(`locations`, payload)

export const uploadInvoice = async (formData: FormData, id?: string) =>
  await instance.post(`submissions/invoice/${id}`, formData)

export const downloadInvoice = async (id?: string) =>
  await instance.get(`submissions/invoice/${id}`)

export const assigUserLocations = async (payload: IAssignUserLocationsPayload) =>
  await instance.post(`userLocation`, payload)

export const addNewContact = async (payload: { email: string, name: string, message: string, }) => {
  const res = await instance.post(`/contact`, payload)
  return res.data
}