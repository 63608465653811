import { ChangeEvent, FormEvent, useState, useEffect } from 'react'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingSelector } from '../../../redux/data/selectors'
import { userBasicData, useUserStatus } from '../../../redux/auth/selectors'
import { IUpdatePayload } from '../../../redux/auth/types'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import * as S from '../Submission/Submission.styles'
import * as A from '../../../redux/auth/actionTypes'
import { updateUser } from '../../../api/userAPI'

export default function ProfileSettings() {
  const data = useSelector(userBasicData)
  const [userData, setUserData] = useState<{ name: string; surname: string; email: string }>(data)
  const [password, setPassword] = useState('')
  const [passwordNew1, setPasswordNew1] = useState('')
  const [passwordNew2, setPasswordNew2] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>()
  const [error, setError] = useState<string | undefined>()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { isAdmin } = useSelector(useUserStatus)
  const dispatch = useDispatch()
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    if (!data) {
      return
    }
    setUserData(data)
  }, [data])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setError('')
    let payload: IUpdatePayload = { ...userData, password }
    if (passwordNew1 && passwordNew1 !== passwordNew2) {
      return setPasswordError('Wprowadzone hasła nie są zgodne')
    }
    if (passwordNew1 && passwordNew1 === passwordNew2) {
      payload = { ...payload, newPassword: passwordNew1 }
    }

    dispatch({ type: A.UPDATE_USER })
    updateUser(payload).then(
      (response: any) => {
        dispatch({ type: A.UPDATE_USER_SUCCESS, data: response })
        clearForm();
      },
      (error: any) => {
        setError('Zmiana hasła nie powiodła się - sprawdź formularz')
        if (error.response.status === 401) {
          dispatch({ type: A.UPDATE_USER_ERROR, data: error.response.data })
        }
        console.log('An error occurred.', error)
      },
    )
  }

  const onClearForm = () => setIsDialogOpen(true)

  const clearForm = () => {
    setPassword('')
    setPasswordNew1('')
    setPasswordNew2('')
  }

  const onCancel = () => setIsDialogOpen(false)
  const onConfirm = () => {
    clearForm();
    setIsDialogOpen(false)
  }

  const { name, surname, email } = userData

  const disableEditRow = !isAdmin ? true : false;
  return (
    <DashboardWrapper header={'Edytuj dane'}>
      <ConfirmationDialog
        body={"Czy na pewno chcesz wyczyścić formularz?"}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <S.FormWrapper onSubmit={handleSubmit} autoComplete="off">
        <S.Wrapper>
          <S.Row>
            <S.InputLabel>Imę:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                disabled={disableEditRow}
                required
                value={name}
                onChange={(e: ChangeEvent<{ value: string }>) =>
                  setUserData({ ...userData, name: e.target.value })
                }
                autoComplete="username"
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Nazwisko:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                disabled={disableEditRow}
                type={'text'}
                required
                value={surname}
                onChange={(e: any) => setUserData({ ...userData, surname: e.target.value })}
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Email:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput disabled={disableEditRow} type={'text'} defaultValue={email} />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Hasło:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                minLength={5}
                required
                type={'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="password"
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Nowe hasło:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                minLength={5}
                type={'password'}
                value={passwordNew1}
                onChange={(e) => {
                  setPasswordError(undefined)
                  setPasswordNew1(e.target.value)
                }}
                autoComplete="new-password"
              />
            </S.InputWrapper>
          </S.Row>
          <S.Row>
            <S.InputLabel>Powtórz hasło:</S.InputLabel>
            <S.InputWrapper>
              <S.SmallInput
                minLength={5}
                type={'password'}
                value={passwordNew2}
                onChange={(e) => {
                  setPasswordError(undefined)
                  setPasswordNew2(e.target.value)
                }}
                autoComplete="new-password"
              />
            </S.InputWrapper>
          </S.Row>
        </S.Wrapper>
        {passwordError && (
          <span>{passwordError}</span>
        )}
        {error && (
          <span style={{textAlign:"center", paddingBottom: '15px'}}>{error}</span>
        )}
        <S.CommonButtonsContainer>
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            label={'Wyczyść'}
            onClick={onClearForm}
            theme={'cancel'}
          />
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            type="submit"
            theme={'save'}
            label={data ? 'Zapisz' : 'Dodaj'}
          />
        </S.CommonButtonsContainer>
      </S.FormWrapper>
    </DashboardWrapper>
  )
}
