import Till from '../partials/Till/Till'
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper'
// @ts-ignore
import File from '../../public/sprzet-laboratoryjny-i-odczynniki-specyfikacja.xlsx'
import * as S from './Request.styles'

const LabEquipment = () => {
  return (
    <WidePhotoSectionWrapper title={'Sprzęt laboratryiny i odczynniki'}>
      <S.Wrapper>
        <S.MessageBody>
          <S.MessageText>14 Maj 2020</S.MessageText>
          <S.MessageText>Szanowni Państwo,</S.MessageText>
          <S.MessageText>
            Kierujemy do Państwa zapytanie ofertowe na zakup sprzętu laboratoryjnego oraz odczynników do hplc.
          </S.MessageText>
          <S.MessageText>Specyfikacja oraz ilości znajdują się w załączonym pliku.</S.MessageText>
          <S.MessageText>Dostawa sprzętu pod wskazany adres w Poznaniu.</S.MessageText>
          <S.MessageText>Podczas oceny ofert pod uwagę brane będą następujące czynniki:</S.MessageText>
          <S.Ul>
            <S.StyledLi>zgodność przedmiotu oferty z potrzebami,</S.StyledLi>
            <S.StyledLi>czas realizacji zamówienia do 14 dni, oraz</S.StyledLi>
            <S.StyledLi>cena.</S.StyledLi>
          </S.Ul>
          <S.MessageText>
            Chcielibyśmy nadmienić, iż nie ma możliwości składania ofert częściowych.
          </S.MessageText>
          <S.MessageText>W przypadku pytań pozostajemy do Państwa dyspozycji.</S.MessageText>
          <br />
          <S.MessageText>Z poważaniem,</S.MessageText>
          <S.MessageText>Prezes Zarządu MonitLab Sp. z o.o.</S.MessageText>
          <S.MessageText>Paulina Zalewska</S.MessageText>
        </S.MessageBody>
        <S.Row>
          <Till isDisplayed header="Składanie ofert">
            <S.TillText>
              Oferty można składać w formie elektronicznej do: 2020-05-25 do godziny 23:59 na adres{' '}
              <S.StyledA href={'mailto:biuro@monitlab.pl'}>biuro@monitlab.pl</S.StyledA>
            </S.TillText>
          </Till>
          <Till isDisplayed header="Załączniki">
            <S.TillLink href={File} download>
              Sprzęt laboratoryjny i&nbsp;odczynniki - specyfikacja
            </S.TillLink>
          </Till>
          <Till isDisplayed header="Wyniki postępowania">
            <S.TillText>
              2020-05-26 W skutek przeprowadzonego postępowania ofertowego, za najkorzystniejszą została
              uznana oferta firmy Alchem Grupa Sp. z o.o.
            </S.TillText>
          </Till>
        </S.Row>
      </S.Wrapper>
    </WidePhotoSectionWrapper>
  )
}

export default LabEquipment
