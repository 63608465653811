import styled from 'styled-components'

export const Link = styled.a`
  border-radius: 4px;
  font-size: 1.5rem;
  color: ${({theme: { mainBlue }}) => mainBlue};
  background-color: ${({theme: { titaniumWhite }}) => titaniumWhite};
  font-family: Poppins;
  font-weight: 600;
  padding: 1rem;
  margin: 0 0 1rem;
  width: fit-content;
  display: block;
  :hover {
    filter: invert(1);
  }
  transition: 250ms;
`


export const EmptyMessageWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`

export const EmptyMessageText = styled.div`
  font-family: 'Poppins';
  color: #00305C;
  font-size: 24px;
  font-weight: 500;
`
