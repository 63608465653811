export const GET_ALL_SUBMISSIONS_SUCCESS = 'GET_ALL_SUBMISSIONS_SUCCESS'
export const GET_ALL_SUBMISSIONS_FAIL = 'GET_ALL_SUBMISSIONS_FAIL'
export const GET_ALL_SUBMISSIONS = 'GET_ALL_SUBMISSIONS'

export const GET_SUBMISSION_SUCCESS = 'GET_SUBMISSION_SUCCESS'
export const GET_SUBMISSION_FAIL = 'GET_SUBMISSION_FAIL'
export const GET_SUBMISSION = 'GET_SUBMISSION'

export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS'
export const CREATE_SUBMISSION_FAIL = 'CREATE_SUBMISSION_FAIL'
export const CREATE_SUBMISSION = 'CREATE_SUBMISSION'

export const UPDATE_SUBMISSION_SUCCESS = 'UPDATE_SUBMISSION_SUCCESS'
export const UPDATE_SUBMISSION_FAIL = 'UPDATE_SUBMISSION_FAIL'
export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION'

export const DELETE_SUBMISSION_SUCCESS = 'DELETE_SUBMISSION_SUCCESS'
export const DELETE_SUBMISSION_FAIL = 'DELETE_SUBMISSION_FAIL'
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION'

export const GET_ALL_LOCATIONS_SUCCESS = 'GET_ALL_LOCATIONS_SUCCESS'
export const GET_ALL_LOCATIONS_FAIL = 'GET_ALL_LOCATIONS_FAIL'
export const GET_ALL_LOCATIONS = 'GET_ALL_LOCATIONS'

export const ASSIGN_USER_LOCATION_SUCCESS = 'ASSIGN_USER_LOCATION_SUCCESS'
export const ASSIGN_USER_LOCATION_FAIL = 'ASSIGN_USER_LOCATION_FAIL'
export const ASSIGN_USER_LOCATION = 'ASSIGN_USER_LOCATION'

export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL'
export const GET_LOCATION = 'GET_LOCATION'

export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS'
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL'
export const CREATE_LOCATION = 'CREATE_LOCATION'

export const DELETE_LOCATION = 'DELETE_LOCATION'
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS'
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL'

export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL'
export const GET_ALL_USERS = 'GET_ALL_USERS'

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL'
export const CREATE_USER = 'CREATE_USER'

export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS'
export const CHANGE_USER_STATUS_FAIL = 'CHANGE_USER_STATUS_FAIL'
export const CHANGE_USER_STATUS = 'CHANGE_USER_STATUS'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'

export const SET_USER_ERROR_MESSAGE = 'SET_USER_ERROR_MESSAGE'
export const SET_LOCATION_ERROR_MESSAGE ='SET_LOCATION_ERROR_MESSAGE'
