import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 75px;
  padding: 2rem 18px 3rem;
  @media(min-width: 600px) {
    padding: 100px 18px 150px;
  }
`;

export const TextWrapper = styled.p`
  border-radius: 20px;
  padding: 40px;
  max-width: 640px;
  width: 100%;
  margin: 0 8px;
  min-height: 1108px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Text = styled.p`
  line-height: 50px;
  letter-spacing: 0.2px;
  width: 100%;
  margin: 0;
  text-align: justify;
  margin-bottom: 24px;
`;

export const PictureWrapper = styled.div`
  width: 100%;
  max-width: 528px;
  margin: 0 8px;
  min-height: 920px;
  position: relative;
  flex-direction: column;
`;

export const Picture = styled.div`
  width: 508px;
  height: 544px;
  margin: 10px;
  object-fit: cover;
  object-position: center;
  div {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
  }
`;

export const SimpleSvg = styled.div`
    /* height: 180px; */
  @media(min-width: 600px) {
    height: 240px;
  }
  width: auto;
  margin: .5rem;
  div {
    height: 100%;
  }
  img {
    object-fit: contain;
  }
`;