import { AppState } from '../store'

export const dataState = (state: AppState) => state.data

export const isLoadingSelector = (state: AppState) => dataState(state).isLoading

export const isFetchingSelector = (state: AppState) => dataState(state).isFetchingData

export const submissionsSelector = (state: AppState) => {
  const { isFetchingData, submissions } = dataState(state)
  return { isFetchingData, submissions }
}

export const locationsSelector = (state: AppState) => {
  const { isFetchingData, locations } = dataState(state)
  return { isFetchingData, locations }
}

export const usersSelector = (state: AppState) => {
  const { isFetchingData, users } = dataState(state)
  return { isFetchingData, users }
}

export const locationSelector = (state: AppState) => {
  const { isFetchingData, location } = dataState(state)
  return { isFetchingData, location }
}

export const submissionSelector = (state: AppState) => {
  const { isLoading, locations, submission } = dataState(state)
  return { isLoading, locations, submission }
}

export const useLocations = (state: AppState) => dataState(state).locations

export const useCreateUserErrorMessage = (state: AppState) => dataState(state).createUserErrorMessage

export const useCreateLocationErrorMessage = (state: AppState) => dataState(state).createLocationErrorMessage
