import Banner from '../../public/rework/Banner.png';
import Bottom from '../../public/rework/box.svg';
import * as S from './HeaderSection.styles';

interface IHeader {
  header: string;
  description: string;
  backgroundSrc?: any;
}

export default function Header({ header, description, backgroundSrc = Banner }: IHeader) {
  return (
    <S.Wrapper>
      <S.Background>
        {backgroundSrc !== Banner && <S.Veil />}
        <S.Image src={backgroundSrc} alt={'background'} />
      </S.Background>
      <S.Body>
        <S.Div800>
          <S.LabelWrapper>
            <S.Header>{header}</S.Header>
            <S.Description dangerouslySetInnerHTML={{__html: description}} />
          </S.LabelWrapper>
          <S.LabelBottom src={Bottom} alt={'decorative element'} />
        </S.Div800>
      </S.Body>
    </S.Wrapper>
  );
}
