import { Link } from 'react-router-dom'
import Filler from '../../public/rework/filler.jpeg'
import WidePhotoSectionWrapper from '../Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper'
import LabelBottomSvg from '../../public/rework/box.svg'
import * as S from './NewsSection.styles'

export default function NewsSection() {
  return (
    <WidePhotoSectionWrapper title={'Aktualności'}>
      <S.Div800>
        <S.LabelWrapper>
          <S.HeaderH3>Bieżące zamówienia</S.HeaderH3>
        </S.LabelWrapper>
        <S.LabelBottom src={LabelBottomSvg} alt={'decorative element'} />
      </S.Div800>
      <S.Row>
        <S.LinksWrapper>
          <Link to={'/request/lab-equipment'}>
            <S.TillWrapper>
              <S.Label>Sprzęt laboratoryjny i&nbsp;odczynniki</S.Label>
            </S.TillWrapper>
          </Link>
          <Link to={'/request/computers'}>
            <S.TillWrapper>
              <S.Label>Sprzęt komputerowy i&nbsp;peryferia</S.Label>
            </S.TillWrapper>
          </Link>
          <Link to={'/request/laboratory'}>
            <S.TillWrapper>
              <S.Label>
                Laboratorium 30&nbsp;m<sup>2</sup>
              </S.Label>
            </S.TillWrapper>
          </Link>
        </S.LinksWrapper>
        <S.Photo>
          <S.Image src={Filler} alt={'filler photo'} />
        </S.Photo>
      </S.Row>
    </WidePhotoSectionWrapper>
  )
}
