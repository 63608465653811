import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router"
import { checkIfPasswordResetCodeIsValid } from "../../api/userAPI";

export const PasswordResetValidationPage = () => {
  const params = useParams() as { code: string };
  const { code } = params;
  const [isLoading, setIsLoading] = useState(true)
  const [isCodeValid, setIsCodeValid] = useState(false)

  useEffect(() => {
    const checkIfCodeIsValid = async () => {
      const result = await checkIfPasswordResetCodeIsValid(code);
      setIsCodeValid(result.valid)
      setIsLoading(false)
    }
    if (!code) {
      return;
    }

    checkIfCodeIsValid();
  }, [code])

  if (isLoading) {
    return null;
  }

  const redirectToUrl = isCodeValid ? '/password-reset-confirm' : '/'

  return <Redirect to={redirectToUrl} />
}

export default PasswordResetValidationPage;