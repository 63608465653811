import { useState } from 'react'
import PulseLoader from 'react-spinners/PulseLoader'
import Theme from '../../../Theme'
import { VscEdit } from 'react-icons/vsc'
import { RiDeleteBin5Line } from 'react-icons/ri'
import * as S from './CommonButton.styles'

export default function CommonButton({
  label,
  onClick,
  styles,
  labelStyles,
  type = 'button',
  disabled = false,
  theme = 'default',
  isLoading,
}: {
  label: string
  onClick?: () => void
  styles?: string
  labelStyles?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  theme?: 'default' | 'save' | 'cancel' | 'delete'
  isLoading?: boolean
}) {
  const [isHover, setIsHover] = useState(false)
  const activeOnClick = disabled ? () => {} : onClick
  const selectedColor = theme === 'save' ? Theme.green : theme === 'cancel' ? Theme.mainRed : Theme.mainBlue
  const labelHover = isLoading ? false : isHover
  const selectedIcon = theme === 'default' ? <VscEdit /> : theme === 'delete' ? <RiDeleteBin5Line /> : ''
  return (
    <S.Button
      colorTheme={theme}
      disabled={disabled || isLoading}
      onMouseEnter={() => disabled === false && setIsHover(true)}
      onMouseLeave={() => disabled === false && setIsHover(false)}
      type={type}
      styles={styles}
      onClick={activeOnClick}
    >
      <S.LabelWrapper
        colorTheme={theme}
        disabled={disabled || isLoading}
        isHover={labelHover}
        styles={labelStyles}
      >
        <S.IconWrapper colorTheme={theme}>
          {selectedIcon}
        </S.IconWrapper>
        {isLoading ? (
          <PulseLoader
            size={10}
            margin={4}
            color={selectedColor}
            loading
          />
        ) : (
          <S.Label colorTheme={theme} disabled={disabled} isHover={isHover} styles={labelStyles}>
            {label}
          </S.Label>
        )}
      </S.LabelWrapper>
    </S.Button>
  )
}
