import { useSelector } from 'react-redux'
import { useUserStatus } from '../../../redux/auth/selectors'
import { Link } from 'react-router-dom';
import Logo from '../../../public/assets/logo 1.png'
import useMediaQuery from '../../../lib/hooks/useMediaQuery'
import LogoutButton from '../LogoutButton/LogoutButton';
import * as S from './DashboardPanel.styles'

interface ILink {
    link: string
    name: string
    icon: any
}


const userLinks: ILink[] = [
    { link: '/dashboard/submissions', name: 'Zlecenia', icon: <S.SubmissionIcon /> },
    { link: '/dashboard/locations', name: 'Placówki', icon: <S.LocationIcon /> },
    { link: '/dashboard/settings', name: 'Ustawienia', icon: <S.SettingsIcon /> }
]
const adminLinks: ILink[] = [
    { link: '/dashboard/submissions', name: 'Zlecenia', icon: <S.SubmissionIcon /> },
    { link: '/dashboard/locations', name: 'Placówki', icon: <S.LocationIcon /> },
    { link: '/dashboard/settings', name: 'Ustawienia', icon: <S.SettingsIcon /> },
    { link: '/dashboard/users', name: 'Użytkownicy', icon: <S.UserIcon /> }
]

const DashboardPanel = () => {
    const isDesktop = useMediaQuery('(min-width: 1170px')
    const { isAdmin } = useSelector(useUserStatus)
    const renderedLinks = isAdmin ? adminLinks : userLinks
    return (
        <S.Wrapper>
            <S.LogoContainer>
                <Link to='/'>
                    <S.Logo src={Logo} />
                </Link>
            </S.LogoContainer>
            <S.NavigationWrapper>
                {renderedLinks.map(({ link, name, icon }: ILink, index: number) => (
                    <S.NavigationContainer key={index}   >
                        <S.IconContainer
                            exact
                            activeStyle={{ color: '#C60A14' }}
                            to={link}>
                            {icon}
                        </S.IconContainer>
                        <S.NavigationLink
                            exact
                            activeStyle={{ color: '#C60A14', fontSize: isDesktop ? '20px' : '' }}
                            to={link}
                            key={`navLink_${index}`}
                        >
                            {name}
                        </S.NavigationLink>
                    </S.NavigationContainer>
                ))}
            </S.NavigationWrapper>
            {isDesktop ? <LogoutButton/> : ''}
        </S.Wrapper>
    )
}

export default DashboardPanel