import axios from 'axios'

const PORT = process.env.REACT_APP_SERVER_PORT ? `:${process.env.REACT_APP_SERVER_PORT}` : ''
export const BASE_URL = process.env.REACT_APP_SERVER_IP
  ? `${process.env.REACT_APP_SERVER_IP}${PORT}/api/v1`
  : 'http://localhost:8080/api/v1';
const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

axios.defaults.withCredentials = true

export default instance
