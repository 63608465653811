import styled from 'styled-components';
import { IoMdClose, IoMdCheckmark } from 'react-icons/io';
import { DateTimePicker } from "@material-ui/pickers";
import { TextareaAutosize } from '@material-ui/core'

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
  border-bottom: none;
  border-radius: 4px;
  margin: 0 0 1rem;
  @media (min-width: 800px){
    margin: 1rem 0 1.5rem;
  }
`;

export const Row = styled.div<{ hasNoBorder?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  border-bottom: ${({ hasNoBorder, theme: { mainBlue } }) =>
    hasNoBorder ? 'none' : `1px solid ${mainBlue}`};
  flex-wrap: wrap;
  flex-direction: column;
  @media (min-width: 420px) {
    padding: 0 0 0 1rem;
    flex-direction: row;
  }
`;


export const InputWrapper = styled.div<{ isTransparent?: boolean, isRadio?: boolean }>`
display: ${({ isRadio }) => isRadio ? 'flex' : 'block'};
  width: 100%;
  @media (min-width: 420px) {
    width: 60%;
  }
  @media (min-width: 700px) {
    min-width: 250px;
  }
  background-color: ${({ isTransparent, theme: { titaniumWhite } }) =>
    isTransparent ? 'transparent' : titaniumWhite};
  padding: 0.5rem;
`;

export const SmallInput = styled.input`
  /* padding: 6px 16px; */
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Poppins;
  height: 100%;
  color: #52575C;
  :focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const NoteInput = styled.input`
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Poppins;
  height: auto;
  color: #52575C;
  :focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputLabel = styled.div`
  margin: 0 auto 0 0;
  padding: 8px 3px 8px 3px;
  @media (min-width: 420px) {
    margin: 0.5rem 0 0.5rem 0;
  }
  font-size: 1rem;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-weight: 500;
`;

export const Checkbox = styled.input`
  height: 1.5rem;
  width: 1.5rem;
  border: none;
  cursor: pointer;
  margin: 0.5rem auto 0.5rem 1rem;
`;

export const SamplesHeader = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  font-family: Poppins;
  color: ${({ theme: { mainBlue } }) => mainBlue};
`;

export const SamplesWrapper = styled.ol`
  width: 100%;
  margin: 1rem 0 2rem;
  border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
  border-bottom: none;
  li {
    display: flex;
    align-items: center;
    list-style: decimal inside;
    font-size: 1rem;
    font-family: Poppins;
    color: ${({ theme: { mainBlue } }) => mainBlue};
  }
`;

export const SampleWrapper = styled.div<{ isOpaque?: boolean }>`
  display: flex;
  align-items: baseline;
  text-align: center;
  justify-content: center;
  opacity: ${({ isOpaque }) => (isOpaque ? 0.5 : 1)};
  border-bottom: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
  width: 100%;
  position: relative;
`;

export const Label = styled(InputLabel) <{borderLeft?: boolean, borderRight?: boolean, maxWidth: string }>`
  min-width: 93px;
  border-left: ${({ borderLeft }) => borderLeft ? `1px solid #00305C` : 'none'};
  border-right:  ${({ borderRight }) => borderRight ? `1px solid #00305C` : 'none'};
  padding-left: 4px;
  max-width: ${({maxWidth}) => maxWidth};
  flex: 1;
`;

export const RowLabel = styled(Label)`
  padding: 6px;
  flex-basis: 40px;
  min-width: 40px;
`;

export const SampleInput = styled(SmallInput)`
  background-color: #fff;
  padding-left: 4px;
  margin: 0.125rem;
  border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
`;

export const DeleteSampleButton = styled.button<{ isRed?: boolean, hasPadding?: boolean }>`
  color: ${({ isRed, theme: { mainRed, green } }) => (isRed ? mainRed : green)};
  border: ${({ isRed, theme: { mainRed, green } }) => `1px solid ${isRed ? mainRed : green}`};
  border-radius: 4px;
  font-size: 1rem;
  font-family: Poppins;
  padding: ${({ hasPadding }) => hasPadding ? .5 : .25}rem;
  margin: ${({ hasPadding }) => hasPadding ? `0 0 .5rem` : ``};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  :disabled {
    filter: saturate(.3);
  }
`;

export const CloseIcon = styled(IoMdClose)`
  color: ${({ theme: { mainRed } }) => mainRed};
  width: 1.5rem;
  height: 1.5rem;
`;

export const CheckIcon = styled(IoMdCheckmark)`
  color: ${({ theme: { green } }) => green};
  width: 1.5rem;
  height: 1.5rem;
`;

export const UnlockButton = styled.button`
  font-family: Poppins;
  font-size: 1rem;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  border: ${({ theme: { mainBlue } }) => `1px solid ${mainBlue}`};
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  margin: 0.5rem 0 0;
  width: fit-content;
  :hover {
    color: #fff;
    background: ${({ theme: { mainBlue } }) => mainBlue};
  }
  transition: filter 250ms;
  :disabled {
    filter: saturate(0.5);
  }
`;

export const DatePicker = styled(DateTimePicker)`
width: 350px;
.MuiOutlinedInput-input {
  cursor: pointer;
}`

export const RadioWrapper = styled.div`
display: flex;
text-align: center;
justify-content: center;`

export const RadioContainer = styled.div`
display: flex;
flex-direction: column;
`
export const RadioLabel = styled.div`
display: flex;
align-items: center;
padding-left: 20px;
text-align: left;
min-width: 100px;
font-weight: 500;
font-family: Poppins;
color: #00305C;
cursor: pointer;
`
export const CommonButtonsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-evenly;
@media (min-width: 600px){
  flex-direction: row;
}`

export const StyledTextArea = styled(TextareaAutosize)`
  background-color: #fff;
  margin: 0.125rem;
  border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 1rem;
  font-family: Poppins;
  height: 100%;
  min-height: 27px;
  padding-left: 4px;
  color: #52575C;
  :focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
export const ErrorWrapper = styled.div`
display: flex;
font-family: Poppins;
font-size: 20px;
font-weight: 400;
justify-content: center;
color: rgb(228, 5, 55);
padding-bottom: 10px;
`

export const RowContainer = styled.div <{ flexGrow?: number, maxWidth: string}>`
display: flex;
max-width:${({maxWidth}) => maxWidth ? maxWidth : '100%'};
flex: ${({ flexGrow }) => flexGrow};
padding: 8px 3px 8px 3px;
width: 100%;
align-items: center;
`;

export const UserRowContainer = styled(RowContainer)<{isAdmin?:  boolean}>`
      /* flex-wrap: ${({isAdmin}) => isAdmin ? 'wrap' : 'none'}; */
      flex-wrap: wrap;
      max-width: ${({isAdmin}) => isAdmin ? 'none' : '50%'};
    `
    

export const DeleteButtonContainer = styled.div<{isAdmin?:  boolean}>`
position: ${({isAdmin}) => isAdmin ? 'absolute' : 'static'};
right: 6px;
top: calc(50% - 17px);
margin: auto 0;`

export const UserRowSimpleContainer = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
`

export const NewRowLabel  = styled.div`
display: flex;
min-width: 74px;`