export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_SIGN_IN_ERROR_MESSAGE = 'SET_SIGN_IN_ERROR_MESSAGE'
export const SET_LOADING_DATA = 'SET_LOADING_DATA'
export const SET_LOADING_DATA_SUCCESS = 'SET_LOADING_DATA_SUCCESS'
export const SET_PASSWORD_ERROR_MESSAGE = 'SET_PASSWORD_ERROR_MESSAGE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
