import { IoMdArrowDropup } from 'react-icons/io'
import { ISubmission, SUBMISSION_STATUS } from '../../../../utils/types.d'
import colorTheme from '../../../../Theme'
import CommonButton from '../../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../../DashboardWrapper/DashboardWrapper'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useUserStatus } from '../../../../redux/auth/selectors'
import { updateSubmission, deleteSubmission } from '../../../../redux/data/actions'
import { ForwardRefExoticComponent, RefAttributes, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import Select from 'react-select';
import { getPDFValues, getSortedSubmissions } from '../../../../utils/submissions'
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResultsGenerator from '../../../ResultsGenerator/ResultsGenerator'
import * as S from './SubmissionsList.styles'
import useMediaQuery from '../../../../lib/hooks/useMediaQuery'
import ConfirmationDialog from '../../../Common/ConfirmationDialog/ConfirmationDialog'

export const statusOptions = [
  {
    value: SUBMISSION_STATUS.AWAITING,
    label: 'Oczekuje',
  },
  {
    value: SUBMISSION_STATUS.CANCELED,
    label: 'Odrzucony',
  },
  {
    value: SUBMISSION_STATUS.IN_DELIVERY,
    label: 'W drodze',
  },
  {
    value: SUBMISSION_STATUS.ANALYSIS,
    label: 'W trakcie analizy',
  },
  {
    value: SUBMISSION_STATUS.FINISHED,
    label: 'Zakończone',
  },
]

interface IAdminView {
  submissions: ISubmission[]
}

interface IStatusProps {
  id: string,
  value: SUBMISSION_STATUS,
}

export default function SubmissionsList({ submissions }: IAdminView) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isAdmin } = useSelector(useUserStatus)
  const handleUpdateStatus = ({ id, value }: IStatusProps) => {
    const submission = submissions.find(t => t.id === id)
    if (!submission) {
      return null;
    }
    const newSubmission = { ...submission, status: value }
    dispatch(updateSubmission(newSubmission))
  }

  const cellStyle = {
    backgroundColor: colorTheme.titaniumWhite,
    color: '#52575C',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    lineHeight: '140%',
    letterSpacing: '0.09px',
  }
  const showEmptyUsersMessage = getSortedSubmissions(submissions).length <= 0;
  const isDesktop = useMediaQuery('(min-width: 1024px')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteSubmissionId, setDeleteSubmissionId] = useState("");
  const onDeleteSubmission = (id: string) => {
    setDeleteSubmissionId(id)
    setIsDialogOpen(true)
  }

  const onConfirm = () => {
    if (!deleteSubmissionId) {
      return;
    }
    dispatch(deleteSubmission(deleteSubmissionId))
    setIsDialogOpen(false)
  }

  const onCancel = () => setIsDialogOpen(false)

  return (
    <DashboardWrapper
      header={'Zlecenia analiz'}
      href={isAdmin ? "" : '/dashboard/submissions/new'}
      linkText={'Utwórz nowe zlecenie'}
      icon={<BiPlus />}
    >
      <ConfirmationDialog
        body={"Czy na pewno chcesz usunąć zlecenie?"}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <MaterialTable
        icons={{
          SortArrow: IoMdArrowDropup as ForwardRefExoticComponent<RefAttributes<SVGSVGElement>>,
        }}
        options={{
          maxBodyHeight: isDesktop ? '80vh' : '55vh',
          showEmptyDataSourceMessage: false,
          overflowY: 'visible',
          headerStyle: {
            zIndex: 1,
            backgroundColor: '#f5f5f5',
            color: colorTheme.mainBlue,
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '160%',
            letterSpacing: '0.09px',
            boxSizing: 'border-box',
            width: '100%',
          },
          selection: false,
          toolbar: false,
          paging: false,
          exportButton: false,
          rowStyle: {
            height: '60px',
            position: 'relative',
            overflow: 'visible'
          },
        }}
        components={{
          Container: (props: any) => <S.StyledTableContainer {...props} />,
        }}
        columns={[
          {
            title: 'Identyfikator',
            field: 'requestName',
            cellStyle,
          },
          {
            title: 'Placówka',
            field: 'location.name',
            cellStyle,
            render: ({ location }: ISubmission) => <>{location ? `${location.name}` : ''}</>,
          },
          {
            title: 'Priorytet',
            field: 'isPriority',
            cellStyle,
            render: ({ isPriority }: ISubmission) => <>{isPriority ? 'Tak' : 'Nie'}</>,
          },
          {
            title: 'Liczba próbek',
            field: 'samples.length',
            cellStyle,
          },
          {
            title: 'Status',
            cellStyle,
            render: (submission: ISubmission) => {
              const { status, id } = submission
              return isAdmin ? (
                <S.InputWrapper>
                  <Select
                    placeholder="Status"
                    options={statusOptions}
                    onChange={({ value: NewStatus }: any) => handleUpdateStatus({ id: id || "", value: NewStatus })}
                    value={statusOptions.find(({ value }: { value: any, label: any }) => value === status)}
                  />
                </S.InputWrapper>
              ) : (
                <>{statusOptions.find(({ value }: { value: any, label: any }) => value === status)!.label}</>
              )
            },
          },
          isAdmin
            ? {
              title: 'Akcje',
              field: 'samplesAmount',
              cellStyle,
              render: ({ id, status }: ISubmission) =>
                isAdmin || status === SUBMISSION_STATUS.AWAITING ? (
                  <CommonButton
                    type={'button'}
                    theme={'save'}
                    label={'Edytuj wyniki'}
                    onClick={() => history.push(`/dashboard/submissions/${id}`)}
                  />
                ) : (
                  <CommonButton
                    type={'button'}
                    theme={'save'}
                    label={'Pobierz wyniki'}
                    disabled={status !== SUBMISSION_STATUS.FINISHED}
                  />
                ),
            }
            : {
              title: 'Wyniki',
              sorting: false,
              cellStyle,
              render: (sub: ISubmission) => {
                const { status, id } = sub;
                const resultsProps = getPDFValues(sub)
                return status === SUBMISSION_STATUS.AWAITING ? (
                  <S.CommonButtonsContainer>
                    <CommonButton
                      type={'button'}
                      theme={'default'}
                      label={'Edytuj'}
                      onClick={() => history.push(`/dashboard/submissions/${id}`)}
                    />
                    <CommonButton
                      type={'button'}
                      theme={'delete'}
                      label={'Usuń'}
                      // styles={'margin-top: .5rem;'}
                      onClick={() => id && onDeleteSubmission(id)}
                    />
                  </S.CommonButtonsContainer>
                ) : (
                  status === SUBMISSION_STATUS.FINISHED && (
                    <>
                      <PDFDownloadLink document={<ResultsGenerator {...resultsProps} />} fileName="wyniki.pdf">
                        <CommonButton
                          type={'button'}
                          theme={'save'}
                          label={'Pobierz wyniki'}
                        />
                      </PDFDownloadLink>
                    </>
                  )
                )
              },
            },
        ]}
        data={getSortedSubmissions(submissions)}
      />
      <S.EmptyMessageWrapper>
        {showEmptyUsersMessage && (
          <S.EmptyMessageText>
            Brak wpisów
          </S.EmptyMessageText>
        )}
      </S.EmptyMessageWrapper>
    </DashboardWrapper>
  )
}
