import { IUpdatePayload, IUser } from '../redux/auth/types'
import instance from './axiosProvider'

export const checkLoggedIn = async () => {
  try {
    const res = await instance.get(`/auth`)
    const preloadedState = {
      auth: {
        user: {
          ...res.data.user,
        },
        isLoggedIn: true,
      },
    }
    return preloadedState
  } catch (e) {
    const preloadedState = {
      auth: {
        user: {},
        isLoggedIn: false,
      },
    }
    return preloadedState
  }
}

export const loginLocally = async (params: { email: string; password: string }): Promise<IUser> => {
  const res = await instance.post(`/auth/login`, { ...params })
  return res.data
}

export const logout = async (): Promise<any> => {
  const res = await instance.delete(`/auth`)
  return res.data
}

export const createUser = async (payload: { email: string; password: string }): Promise<IUser> => {
  const res = await instance.post(`/auth/create`, payload)
  return res.data
}

export const updateUser = async (payload: IUpdatePayload): Promise<IUser> => {
  const res = await instance.patch(`/auth`, payload)
  return res.data
}

export const sendPasswordResetIntent = async (email: string) => {
  const res = await instance.post(`/auth/password-reset/intent`, { email })
  return res.data
}

export const checkIfPasswordResetCodeIsValid = async (code: string): Promise<{valid: boolean}> => {
  const res = await instance.post(`/auth/password-reset/verify`, { code })
  return res.data
}

export const confirmPassworReset = async (email: string, temporaryPassword: string, newPassword: string) => {
  const res = await instance.post(`/auth/password-reset`, { email, temporaryPassword, newPassword })
  return res.data
}