import { createStyles, TableContainer, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Button = styled.button`
  font-family: Poppins;
  font-weight: 600;
  font-size: 1rem;
  width: 75%;
  padding: .5rem 1rem;
  white-space: nowrap;
  border-radius: 4px;
  color: ${({theme: { secondaryBlue }}) => secondaryBlue};
  background-color: transparent;
  border: 1px solid ${({theme: { secondaryBlue }}) => secondaryBlue};
  :hover {
    filter: invert(1);
  }
  transition: 250ms;
  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }
`;

export const InputWrapper = styled.form`
min-width: 160px;
`;

export const SmallInput = styled.input`
  width: 75%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { mainBlue } }) => mainBlue};
  color: ${({ theme: { mainBlue } }) => mainBlue};
  border-radius: 4px;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-family: Poppins;
  :focus {
    outline: none;
  }
  :placeholder {
    color: ${({ theme: { mainBlue } }) => mainBlue}ff;
  }
`;

export const Link = styled.a`
  border-radius: 4px;
  font-size: 1.5rem;
  color: ${({theme: { mainBlue }}) => mainBlue};
  background-color: ${({theme: { titaniumWhite }}) => titaniumWhite};
  font-family: Poppins;
  font-weight: 600;
  padding: 1rem;
  margin: 0 0 1rem;
  width: fit-content;
  display: block;
  :hover {
    color: ${({theme: { titaniumWhite }}) => titaniumWhite};
    background: ${({theme: { mainBlue }}) => mainBlue};
  }
  transition: 500ms;
`

export const StyledTableContainer = withStyles(() =>
  createStyles({
    root: {
      borderRadius: '8px',
      marginBottom: '26px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.03)',
      overflow: 'visible',
      // "& .Component-horizontalScrollContainer-6": {
      //   'overflow-x': 'visible !important'
      // },
      "& > div": {
        'overflow-x': 'visible !important'
      }
    },
  })
)(TableContainer)

export const CommonButtonsContainer = styled.div`
display: flex;`

export const EmptyMessageWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
`

export const EmptyMessageText = styled.div`
  font-family: 'Poppins';
  color: #00305C;
  font-size: 24px;
  font-weight: 500;
`