import { FormEvent, useEffect, useState } from 'react'
import { submissionSelector } from '../../../redux/data/selectors'
import { ISubmissionBasic, SUBMISSION_STATUS } from '../../../utils/types.d'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import RequestForm from './RequestForm'
import SamplesForm from './SamplesForm'
import { useDispatch, useSelector } from 'react-redux'
import { createSubmission, updateSubmission } from '../../../redux/data/actions'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import * as S from './Submission.styles'

const emptySubmission: ISubmissionBasic = {
  locationId: '',
  requestName: '',
  isPriority: false,
  samplesAmount: 0,
  samples: [],
  isAccepted: false,
  isCanceled: false,
  status: SUBMISSION_STATUS.AWAITING,
}

const emptySample = {
  vialId: '',
  name: '',
  // submissionId: string
  isNew: true,
  personName: '',
  personSurname: '',
  personIdentification: ''
}

const Submission = () => {
  const dispatch = useDispatch()
  const { isLoading, locations, submission } = useSelector(submissionSelector)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [tempSubmission, setTempSubmission] = useState<ISubmissionBasic>(
    submission ? submission : emptySubmission,
  )
  const clearForm = () => setTempSubmission(submission ? submission : emptySubmission)

  useEffect(() => {
    if (submission) {
      setTempSubmission(submission)
    } else {
      clearForm()
    }
  }, [submission])

  // //@ts-ignore
  // const localizationOptions: any[] = locations.map(({ location: { name, secondaryName, id } }) => ({ label: `${name} ${secondaryName}`, value: id }))

  const localizationOptions: any[] = []
  for (let index = 0; index < locations.length; index++) {
    const { name, secondaryName, id } = locations[index]
    localizationOptions.push({ label: `${name} ${secondaryName}`, value: id })
  }

  const editData = (field: string, value: any) => setTempSubmission({ ...tempSubmission, [field]: value })

  const { samples } = tempSubmission

  const addSample = () => {
    setTempSubmission({
      ...tempSubmission,
      samplesAmount: tempSubmission.samplesAmount + 1,
      samples: [...samples, emptySample],
    })
  }

  const handleSubmit = (e: FormEvent) => {
    if (!locationId) {
      setIsNoLocationError(true)
    }
    e.preventDefault()
    if (tempSubmission.id) {
      return dispatch(updateSubmission(tempSubmission))
    }
    dispatch(createSubmission(tempSubmission))
  }

  const handleSampleUpdate = (index: number, field: string, value: any) => {
    const selectedSample = samples[index]
    const prevSamples = samples.slice(0, index),
      postSamples = samples.slice(index + 1)

    if (selectedSample.isNew && field === 'isDeleted') {
      return setTempSubmission({
        ...tempSubmission,
        samplesAmount: tempSubmission.samplesAmount - 1,
        samples: [...prevSamples, ...postSamples],
      })
    }
    if (field === 'isDeleted' && value) {
      setTempSubmission({
        ...tempSubmission,
        samples: [...prevSamples, { ...selectedSample, isDirty: true, [field]: value }, ...postSamples],
        samplesAmount: tempSubmission.samplesAmount - 1,
      })
    } else if (field === 'isDeleted' && !value) {
      setTempSubmission({
        ...tempSubmission,
        samples: [...prevSamples, { ...selectedSample, isDirty: true, [field]: value }, ...postSamples],
        samplesAmount: tempSubmission.samplesAmount + 1,
      })
    }
    setTempSubmission({
      ...tempSubmission,
      samples: [...prevSamples, { ...selectedSample, isDirty: true, [field]: value }, ...postSamples],
    })
  }
  const onClearForm = () => setIsDialogOpen(true)


  const onCancel = () => setIsDialogOpen(false)
  const onConfirm = () => {
    clearForm()
    setIsDialogOpen(false)
  }
  const { locationId } = tempSubmission
  const [isNoLocationError, setIsNoLocationError] = useState(false)

  useEffect(() => {
    setIsNoLocationError(false)
  }, [locationId])

  return (
    <DashboardWrapper header={submission ? 'Edytuj zlecenie' : 'Nowe zlecenie'}>
      <ConfirmationDialog
        body={'Czy na pewno chcesz wyczyścić formularz?'}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <S.FormWrapper onSubmit={handleSubmit}>
        {
          <RequestForm
            localizationOptions={localizationOptions}
            data={tempSubmission}
            editData={editData}
            noLocationError={isNoLocationError}
          />
        }
        {
          <SamplesForm
            handleAddSample={addSample}
            samples={samples}
            handleSampleUpdate={handleSampleUpdate}
            isInProgress={submission ? SUBMISSION_STATUS.AWAITING !== submission.status : false}
            isNew={!!submission?.id}
          />
        }
        <S.Row hasNoBorder>
          <CommonButton
            isLoading={isLoading}
            type={'reset'}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem; width: 138px;`}
            label={'Wyczyść'}
            theme={'cancel'}
            onClick={onClearForm}
          />
          <CommonButton
            isLoading={isLoading}
            styles={`margin: 0 auto; width: 138px;`}
            labelStyles={`padding: 1rem 2rem;`}
            type="submit"
            theme={'save'}
            label={submission ? 'Zapisz' : 'Dodaj'}
          />
        </S.Row>
      </S.FormWrapper>
    </DashboardWrapper>
  )
}

export default Submission
