import MonitoringTDM from '../components/MonitoringTDM/MonitoringTDM'
import PersonalizedMedicine from '../components/PersonalizedMedicine/PersonalizedMedicine'
import WidePhotoSectionWrapper from '../components/Common/WidePhotoSectionWrapper/WidePhotoSectionWrapper'
import TherapyFactors from '../components/TherapyFactors/TherapyFactors'
import Quantity from '../public/rework/quantity.jpeg'

const TDM = () => (
  <WidePhotoSectionWrapper
    title={'Terapia Monitorowana - TDM'}
    photo={Quantity}
    alt={'laboratory quantity test'}
  >
    <MonitoringTDM />
    <PersonalizedMedicine />
    <TherapyFactors />
  </WidePhotoSectionWrapper>
)

export default TDM
