import Footer from '../Footer/Footer'
import { useSelector } from 'react-redux'
import { isUserLoggedIn } from '../../../redux/auth/selectors'
import Navbar from '../Navbar/Navbar'
import DashboardPanel from '../../Dashboard/DashboardPanel/DashboardPanel'
import LogoutButton from '../../Dashboard/LogoutButton/LogoutButton'
import useMediaQuery from '../../../lib/hooks/useMediaQuery'
import * as S from './Layout.styles'

interface ILayout {
  children?: JSX.Element | JSX.Element[]
}

export default function Layout({ children }: ILayout) {
  const isLoggedIn = useSelector(isUserLoggedIn)
  const isTablet = useMediaQuery('(min-width: 1170px)')
  return (
    <S.Wrapper isLoggedIn={isLoggedIn}>
      {isLoggedIn ? <DashboardPanel/> : <Navbar /> }
      <S.Main>{children}</S.Main>
      {isLoggedIn  ? !isTablet ? <S.LougoutButtonContainer><LogoutButton/></S.LougoutButtonContainer> : ''
      : <Footer />}
    </S.Wrapper>
  )
}
