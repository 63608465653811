import * as S from './WhyImportant.styles';
import shield from '../../public/rework/icons/shield.svg'
import heart from '../../public/rework/icons/heart.svg'
import data from '../../public/rework/icons/data.svg'
import bottom from '../../public/rework/box.svg'

export default function WhyImportant() {
  return (
    <S.Wrapper>
      <S.BodyWrapper>
        <S.Div800>
          <S.LabelWrapper>
            <S.Header>Dlaczego projekt jest ważny?</S.Header>
            <S.Description>
              Inwazyjne zakażenia grzybicze (IFI –&nbsp;invasive fungal infection) stanowią istotne
              powikłanie współczesnych terapii onkologicznych i&nbsp;hematologicznych, utrudniające
              leczenie oraz zwiększające śmiertelność pacjentów z&nbsp;obniżoną odpornością.
              {'\n\n'}
              Do grupy wysokiego ryzyka IFI należą chorzy na nowotwory hematologiczne oraz biorcy
              transplantacji allogenicznych komórek krwiotwórczych. Wystąpienie zakażenia
              grzybiczego wiąże się często z&nbsp;koniecznością odroczenia lub przerwania leczenia,
              co niekorzystnie wpływa na wyniki leczenia przeciwnowotworowego.
              {'\n\n'}
              Badania naukowe oraz europejskie rekomendacje ECIL wskazują na konieczność
              monitorowania stężenia pozakonazolu oraz innych leków przeciwgrzybiczych, jako warunek
              skutecznej terapii przeciwgrzybiczej.
            </S.Description>
          </S.LabelWrapper>
          <S.LabelBottom src={bottom} />
        </S.Div800>
        <S.TillsWrapper>
          <S.Row>
            <S.TillWrapper>
              <S.SafetyWrapper>
                <S.Icon src={shield} alt={'blue shield icon'} />
              </S.SafetyWrapper>
              <S.Label>Zwiększenie bezpieczeństwa terapii pacjentów.</S.Label>
            </S.TillWrapper>
            <S.TillWrapper>
              <S.EfficacyWrapper>
                <S.Icon src={heart} alt={'red heart icon'} />
              </S.EfficacyWrapper>
              <S.Label>Zwiększenie skuteczności leczenia.</S.Label>
            </S.TillWrapper>
          </S.Row>
          <S.TillWrapper isWide>
            <S.MoneyWrapper>
              <S.Icon src={data} alt={'green data icon'} />
            </S.MoneyWrapper>
            <S.Label>
              {
                'Ograniczenie kosztów związanych z\u00A0późniejszym leczeniem powikłań lub działań niepożądanych leku, wynikających ze zbyt wysokiego stężenia leku we krwi.'
              }
            </S.Label>
          </S.TillWrapper>
        </S.TillsWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
}
