import { deleteLocation } from '../../../redux/data/actions'
import { IoMdArrowDropup } from 'react-icons/io'
import { isLoadingSelector, useLocations } from '../../../redux/data/selectors'
import { useUserStatus } from '../../../redux/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { BiPlus } from 'react-icons/bi'
import colorTheme from '../../../Theme'
import CommonButton from '../../Common/CommonButton/CommonButton'
import DashboardWrapper from '../DashboardWrapper/DashboardWrapper'
import MaterialTable from 'material-table'
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog'
import * as S from './LocationsTable.styles'
import { history } from '../../../App'

export interface ILocation {
  id: string
  name: string
  secondaryName: string
  street: string
  city: string
  zipCode: string
  createdAt: Date
}

interface ILocationsTable {
  locations: ILocation[]
}

export default function SubmissionsList({ locations }: ILocationsTable) {
  const [currentLocations, setCurrentLocations] = useState<ILocation[]>([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [deleteLocationId, setDeleteLocationId] = useState("");
  const dispatch = useDispatch()
  const { isAdmin } = useSelector(useUserStatus)
  const isLoading = useSelector(isLoadingSelector)

  const formattedUserLocations = useSelector(useLocations)

  useEffect(() => {
    setCurrentLocations(locations)
  }, [locations])

  const cellStyle = {
    backgroundColor: colorTheme.titaniumWhite,
    color: '#52575C',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    lineHeight: '140%',
    letterSpacing: '0.09px',
    paddingLeft: '20px',
  }
  const renderHeader = isAdmin ? 'Placówki' : 'Twoje placówki'
  const renderButtonLink = isAdmin ? '/dashboard/locations/new' : ''
  const renderButtonText = isAdmin ? 'Dodaj' : ''
  const hideColumn = isAdmin ? false : true
  const locationsData = isAdmin ? currentLocations : formattedUserLocations;

  const onDeleteLocation = (id: string) => {
    setDeleteLocationId(id)
    setIsDialogOpen(true)
  }

  const onConfirm = () => {
    if (!deleteLocationId) {
      return;
    }
    dispatch(deleteLocation(deleteLocationId))
    setIsDialogOpen(false)
  }

  const onCancel = () => setIsDialogOpen(false)
  const showEmptyUsersMessage = locationsData.length <= 0;
  return (
    <DashboardWrapper header={`${renderHeader}`} href={`${renderButtonLink}`} linkText={`${renderButtonText}`} icon={<BiPlus />}>
      <ConfirmationDialog
        body={"Czy na pewno chcesz usunąc placówkę?"}
        isOpen={isDialogOpen}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
      <MaterialTable
        icons={{
          // @ts-ignore
          SortArrow: IoMdArrowDropup,
        }}
        options={{
          maxBodyHeight: '50vh',
          showEmptyDataSourceMessage: false,
          headerStyle: {
            zIndex: 1,
            backgroundColor: '#f5f5f5',
            color: colorTheme.mainBlue,
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '160%',
            letterSpacing: '0.09px',
            boxSizing: 'border-box',
            width: '100%',
            paddingLeft: '20px',
          },
          selection: false,
          toolbar: false,
          paging: false,
          exportButton: false,
          rowStyle: {
            height: '60px',
            position: 'relative',
          },
        }}
        columns={[
          {
            title: 'Nazwa',
            field: 'name',
            cellStyle,
          },
          {
            title: 'Nazwa szczegółowa',
            field: 'secondaryName',
            cellStyle,
          },
          {
            title: 'Ulica',
            field: 'street',
            cellStyle,
          },
          {
            title: 'Miasto',
            field: 'city',
            cellStyle,
          },
          {
            title: 'Kod pocztowy',
            field: 'zipCode',
            cellStyle,
          },
          {
            title: 'Akcje',
            cellStyle,
            hidden: hideColumn,
            render: ({ id }: ILocation) => (
              <S.CommonButtonsContainer>
                <CommonButton
                  styles={'width: 85px;'}
                  labelStyles={'width: 85px;'}
                  label={'Edytuj'}
                  onClick={() => {
                    history.push(`/dashboard/locations/${id}`)
                  }}
                  isLoading={isLoading}
                />
                <CommonButton
                  styles={'width: 85px;'}
                  labelStyles={'width: 85px;'}
                  label={'Usuń'}
                  theme={'delete'}
                  onClick={() => onDeleteLocation(id)}
                  isLoading={isLoading}
                />
              </S.CommonButtonsContainer>
            ),
          },
        ]}
        data={locationsData}
      />
      <S.EmptyMessageWrapper>
        {showEmptyUsersMessage && (
          <S.EmptyMessageText>
            Brak wpisów
          </S.EmptyMessageText>
        )}
      </S.EmptyMessageWrapper>
    </DashboardWrapper>
  )
}
