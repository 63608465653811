const theme = {
  mainBlue: '#00305C',
  green: '#38ad73',
  yellow: '#ffbb00',
  orange: `#ff8566`,
  mainBackground: '#DEE4EA',
  mainRed: '#C60A14',
  paleBlue: '#F0F3F7',
  secondaryBlue: '#175FFF',
  white: '#f1f1f1',
  titaniumWhite: '#fff',
  lightGray: '#E8E8E8',
  black: '#23242A',
  gradient1: '#797EF6',
  gradient2: '#7DD6F6',
}

export default theme
