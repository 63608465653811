import { FormEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { loginLocally } from '../../redux/auth/actions'
import { authSelector } from '../../redux/auth/selectors'
import CommonButton from '../Common/CommonButton/CommonButton'
import * as S from './Login.styles'

export default function Login() {
  const dispatch = useDispatch()
  const { isLoading, loginErrorMessage } = useSelector(authSelector)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(loginLocally({ email, password }))
  }
  const history = useHistory()
  const handleGoToResetPassowrd = () => {
    history.push('/password-reset')
  }

  return (
    <S.Wrapper>
      <S.LoginHeaderWrapper>
        <S.LoginHeader>Zaloguj się</S.LoginHeader>
      </S.LoginHeaderWrapper>
      <S.LoginForm onSubmit={handleSubmit}>
        <S.Input
          required
          placeholder={'E-mail'}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <S.Input
          type="password"
          required
          placeholder={'Hasło'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <S.CommonButtonsWrapper>
          <CommonButton isLoading={isLoading} theme={'cancel'} label={'Zresetuj hasło'} onClick={handleGoToResetPassowrd} />
          <CommonButton isLoading={isLoading} theme={'save'} label={'Zaloguj się'} type={'submit'} />
        </S.CommonButtonsWrapper>
      </S.LoginForm>
      <S.ErrorMessage isDisplayed={!!loginErrorMessage}>{loginErrorMessage}</S.ErrorMessage>
      <S.AdminContact isDisplayed={!!loginErrorMessage} href={'mailto:biuro@monitlab.pl'}>
        Jesli potrzebujesz pomocy napisz do nas: biuro@monitlab.pl
      </S.AdminContact>
    </S.Wrapper>
  )
}
