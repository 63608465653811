import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  box-sizing: border-box;
  margin: 2rem 0;
  @media(min-width: 600px) {
    padding: 0 18px;
    margin: 2rem 0 6rem;
  }
`;

export const BodyWrapper = styled.div<{isLeft?: boolean}>`
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (min-width: 600px) {
    padding: 0 2rem;
  }
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: ${({isLeft}) => isLeft ? 'row' : 'row-reverse'};
  }
  box-sizing: border-box;
`;

export const PhotoColumn = styled.aside<{isContain: boolean}>`
  width: 100%;
  margin: 0 0 2rem;
  @media (min-width: 1024px) {
    min-width: 40%;
    width: 40%;
  }
  /* hide on mobile view */
  /* display: none;
  @media (min-width: 600px) {
    display: block;
  } */
`;

export const TextColumn = styled.aside`
  display: flex;
  flex-direction: column;
  @media(min-width: 600px) {
    padding: 0rem 1rem;
  }
  flex-grow: 1;
`;

export const List = styled.ul`
  background-color: #fff;
  white-space: pre-line;
  border-radius: 8px;
  padding: 3rem;
  @media (min-width: 1024px) {
    margin: 2rem;
  }
`;

export const Text = styled.p`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1rem;
  line-height: 240%;
  background-color: #fff;
  white-space: pre-line;
  border-radius: 8px;
  padding: 3rem;
  @media (min-width: 1024px) {
    margin: 2rem;
  }
`

export const ListItem = styled.li`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  font-size: 1rem;
  line-height: 160%;
  margin: 0 0 0.75rem;
`;

export const Header = styled.h2`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  margin: 0 0 2rem;
  font-size: 2rem;
  @media (min-width: 500px) {
    font-size: 3rem;
  }
  @media (min-width: 800px) {
    font-size: 4rem;
  }
  @media (min-width: 1024px) {
    margin: 0 2rem 2rem;
  }
`;

export const Image = styled.img<{isContain?: boolean}>`
  width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: ${({isContain}) => isContain ? 'contain' : 'cover'};
`