import styled from 'styled-components'
import { CommonInput } from '../ContactSection/ContactSection.styles'

export const Wrapper = styled.div`
  max-width: 1500px;
  margin: 2rem auto;
  padding: 2rem;
  height: calc(100vh - 250px);
  background-color: ${({ theme: { titaniumWhite } }) => titaniumWhite};
  `

  export const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 500px;
  margin: auto;
  `

  export const ResetPasswordHeader = styled.div`
  color: ${({ theme: { mainBlue } }) => mainBlue};
  font-family: Poppins;
  margin: 0 auto 2rem;
  font-size: 2rem;
  max-width: 500px;
  @media (min-width: 500px) {
    font-size: 3rem;
  }
  @media (min-width: 1024px) {
    margin: 0 auto 2rem;
  }`

export const Input = styled.input`
${CommonInput}
background-color: #fff;
box-shadow: 5px 5px 15px ${({ theme: { mainBlue } }) => mainBlue}55;
color: ${({ theme: { mainBlue } }) => mainBlue};
:placeholder {
  color: ${({ theme: { mainBlue } }) => mainBlue};
}
`

export const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
`

export  const HeaderWrapper = styled.div`
display: flex;
justify-content: center;
`

export const ErrorContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
padding-bottom: 16px;
`

export const Error = styled.span`
font-family: Poppins;
font-weight: 400;
font-size: 16px;
color: #E43727;
`
export const Span = styled.span`
min-height: 41px;`