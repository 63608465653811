import styled from 'styled-components'
import { GoHome } from 'react-icons/go'
import { BiLogOut } from 'react-icons/bi'
import { FiSettings } from 'react-icons/fi'
import { BiUserCircle } from 'react-icons/bi'
import { MdOutlinePlace, MdOutlineBorderColor } from 'react-icons/md'
import { NavLink } from 'react-router-dom'

export const Wrapper = styled.section`
display: flex;
flex-direction: column;
width: 100%;
background: white;
padding: 15px 25px;
font-family: Poppins;
font-size: 14px;
color: #00305C;
@media (min-width: 1170px){
    height: 100vh;
    padding: 30px 25px;
    width: auto;
    max-width: 500px;
    font-size: 16px;
}
`
export const LogoContainer = styled.div`
display: flex;
justify-content: center;
padding-bottom: 16px;
@media (min-width: 1170px){
    display: block;
    padding-bottom: 50px;
}`

export const Logo = styled.img``

export const NavigationWrapper = styled.div`
display: flex;
flex-wrap: wrap;
border-bottom: 1px solid #E8E8E8;
justify-content: space-around;
@media (min-width: 570px){
flex-wrap: nowrap;
justify-content: center;
}
@media (min-width: 1170px){
    flex-direction: column;
    justify-content: normal;
    flex-wrap: nowrap;
    margin-bottom: 16px;
}
`

export const NavigationContainer = styled.div`
display: flex;
align-items: center;
padding-bottom: 10px;
padding-right: 10px;
min-width: 140px;
&:hover {
    color: red;
}
@media (min-width: 1170px){
    padding-bottom: 16px;
    padding-right: 0px;
    min-width: 0px;
}
`

export const IconContainer = styled(NavLink)`
  padding-right: 8px;
  color: #00305C;
  display: flex;
`

export const ButtonIconContainer = styled.div`
padding-right: 6px;
display: flex;
`

export const NavImage = styled.img``

export const NavigationLink = styled(NavLink)`
color: #00305C;`

export const ButtonContainer = styled.div`
font-family: Poppins;
display: flex;
align-items: center;
padding-right: 6px;
justify-content: center;
border: 1px solid gray;
border-radius: 8px;
cursor: pointer;
max-width: 180px;
`

export const Button = styled.button`
color: #00305C;
transition: 500ms;
font-weight: 600;
`

const Icon =
  `
width: 25px;
height: 25px;
`
export const HomeIcon = styled(GoHome)`
${Icon}
`

export const SettingsIcon = styled(FiSettings)`
${Icon}
`

export const UserIcon = styled(BiUserCircle)`
${Icon}
`

export const LogoutIcon = styled(BiLogOut)`
${Icon}

`

export const LocationIcon = styled(MdOutlinePlace)`
${Icon}
`

export const SubmissionIcon = styled(MdOutlineBorderColor)`
${Icon}
`
