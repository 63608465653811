import { useState } from "react"
import { sendPasswordResetIntent } from "../../api/userAPI"
import CommonButton from "../Common/CommonButton/CommonButton"
import * as S from './PasswordResetPage.styles'

export const PasswordResetPage = () => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessageVisible, setErrorMessageVisible] = useState(false)
  const [successMessageVisible, setSuccessMessageVisible] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMessageVisible(false);
    setSuccessMessageVisible(false);
    setIsLoading(true)
    const result = await sendPasswordResetIntent(email)
    setEmail('')
    setErrorMessageVisible(!result.success)
    setSuccessMessageVisible(result.success)
    setIsLoading(false)
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.ResetPasswordHeader>Reset hasła</S.ResetPasswordHeader>
      </S.HeaderWrapper>
      <S.ResetPasswordForm onSubmit={handleSubmit}>
        <S.Input type="email"
          value={email}
          required
          onChange={e => setEmail(e.target.value)}
          placeholder="email"
        />
        {errorMessageVisible ?
          (<S.ErrorContainer>
            <S.Error>Nie udało się zresetować hasła. Skontakuj się z nami mailowo.</S.Error>
          </S.ErrorContainer>)
          : <S.Span /> }
        {successMessageVisible &&
          <S.ErrorContainer>
            <S.Error>Wysłaliśmy maila z instrukcją zresetowania hasła. Sprawdź swoją skrzynkę odbiorczą.</S.Error>
          </S.ErrorContainer>
        }
        <S.ButtonWrapper>
          <CommonButton
            theme={'save'}
            label={'Zresetuj hasło'}
            type={'submit'}
            disabled={isLoading}
          />
        </S.ButtonWrapper>
      </S.ResetPasswordForm>
    </S.Wrapper>
  )
}

export default PasswordResetPage