import { useState } from "react"
import { confirmPassworReset } from "../../api/userAPI"
import { history } from "../../App"
import CommonButton from '../Common/CommonButton/CommonButton'
import * as S from './PasswordResetConfirmationPage.styles'

export const PasswordResetConfirmationPage = () => {
  const [email, setEmail] = useState("")
  const [temporaryPassword, setNewTemporaryPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [passwordsNotMatchError, setPasswordsNotMatchError] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState("")

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setPasswordsNotMatchError(false)

    if (newPassword !== confirmNewPassword) {
      setPasswordsNotMatchError(true)
    }

    const result = await confirmPassworReset(email, temporaryPassword, newPassword)

    if (result.success) {
      history.push('/login')
    }
  }

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
      <S.ResetPasswordHeader>Reset hasła</S.ResetPasswordHeader>
      </S.HeaderWrapper>
      <S.ResetForm onSubmit={handleSubmit}>
        <S.Input
          type="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="email"
        />
        <S.Input
          type="password"
          required
          value={temporaryPassword}
          onChange={e => setNewTemporaryPassword(e.target.value)}
          placeholder="hasło jednorazowe"
        />
        <S.Input
          type="password"
          required
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          placeholder="nowe hasło"
        />
        <S.Input
          type="password"
          required
          value={confirmNewPassword}
          onChange={e => setConfirmNewPassword(e.target.value)}
          placeholder="powtórz nowe hasło"
        />
        <S.ButtonWrapper>
          <CommonButton theme={'save'} label={'Zatwierdź'} type={'submit'} />
        </S.ButtonWrapper>
        {passwordsNotMatchError &&
        <S.ErrorContainer>
          <S.Error>Podane hasła nie są takie same.</S.Error>
          </S.ErrorContainer>
          }
      </S.ResetForm>
    </S.Wrapper>
  )
}

export default PasswordResetConfirmationPage