import { FaMobileAlt, FaRegEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const Wrapper = styled.nav`
  width: 100%;
`

export const ContactWrapper = styled.div`
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
`

export const BannerWrapper = styled.div`
  background-color: ${({ theme: { mainRed } }) => mainRed};
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
`

export const ContactItem = styled.a`
  font-family: Poppins;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 1rem;
  align-items: center;
`

export const BannerItem = styled.a`
  font-family: Poppins;
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem 1rem;
  align-items: center;
`

export const ContactAddress = styled.div`
  font-family: Poppins;
  margin: 0.5rem 1rem;
  align-items: center;
  display: none;
  @media (min-width: 700px) {
    flex-wrap: wrap;
    display: flex;
  }
`

export const Label = styled.p`
  margin: 0;
  color: ${({ theme: { white } }) => white};
  text-decoration: underline;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

const IconProps = css`
  margin: 0 0.5rem 0 0;
`

export const MobileIcon = styled(FaMobileAlt)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const MailIcon = styled(FaRegEnvelope)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const MapPin = styled(FaMapMarkerAlt)`
  ${IconProps}
  color: ${({ theme: { white } }) => white};
`

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1rem;
  box-sizing: border-box;
  max-width: 1350px;
  margin: 0 auto;
`

export const LinkLogo = styled(Link)`
  height: 52px;
  max-width: 300px;
  flex-grow: 1;
  display: block;
  cursor: pointer;
  position: relative;
`

export const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: fit-content;
  width: 100%;
`

export const Nav = styled.div`
  display: flex;
  align-items: center;
`

export const NavItem = styled(Link)<{ params: { isSelected?: boolean } }>`
  font-family: Poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme: { mainBlue } }) => mainBlue};
  padding: 0.5rem 0.5rem 0.25rem;
  margin: 0.5rem;
  white-space: nowrap;
  position: relative;
  transition: border 300ms;
  position: relative;
  :after {
    height: 2px;
    width: ${({ params: { isSelected } }) => (isSelected ? '100%' : '0')};
    background-color: ${({ theme: { mainBlue } }) => mainBlue};
    position: absolute;
    content: '';
    bottom: 0;
    transition: width ease-in-out 300ms;
    left: 0;
  }
  :hover:after {
    background-color: ${({ theme: { mainBlue } }) => mainBlue};
    width: 100%;
  }
`

export const DocSection = styled.a`
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  border-radius: 2rem;
  color: ${({ theme: { white } }) => white};
  background: ${({ theme: { gradient1, gradient2 } }) =>
    `linear-gradient(270deg, ${gradient1} -4.12%, ${gradient2} 102.94%)`};
  :hover {
    color: ${({ theme: { black } }) => black};
    text-shadow: 0 0 24px ${({ theme: { white } }) => white};
  }
  margin: 1rem 0 0;
  @media (min-width: 1024px) {
    margin: 0;
  }
`

export const BurgerButton = styled.button`
  margin: auto 0 auto 2rem;
`

export const IconWrapper = styled.div`
  position: relative;
  width: 52px;
  height: 38px;
  margin: auto 0 0;
`

const IconEl = `
width: 46px;
height: 4px;
position: absolute;
border-radius: 8px;
  `

export const IconEl1 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  top: ${({ isOpen }) => (isOpen ? '17px' : '2px')};
  transform: rotate(${({ isOpen }) => (isOpen ? '45deg' : '0')});
  transition: 300ms;
`

export const IconEl2 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  top: 17px;
  transition: 300ms;
`

export const IconEl3 = styled.div<{ isOpen: boolean }>`
  ${IconEl}
  background-color: ${({ theme: { mainBlue } }) => mainBlue};
  left: 6px;
  bottom: ${({ isOpen }) => (isOpen ? '17px' : '2px')};
  transform: rotate(${({ isOpen }) => (isOpen ? '-45deg' : '0')});
  transition: 300ms;
`

export const NavMenu = styled.div<{ isOpen?: boolean }>`
  width: 100%;
  position: absolute;
  background-color: ${({ theme: { white } }) => white};
  left: 0;
  top: 180px;
  height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 180px)' : 0)};
  @media (min-width: 395px) {
    top: 150px;
    height: ${({ isOpen }) => (isOpen ? 'calc(100vh - 150px)' : 0)};
  }
  padding: ${({ isOpen }) => (isOpen ? '2rem 1rem' : 0)};
  box-sizing: border-box;
  transition: height 300ms, padding 300ms;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 10;
`
