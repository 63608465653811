//  (oczekujący, odrzucony, w drodze, analiza, oczekuje na opłatę, zakończono)
export enum SUBMISSION_STATUS {
  AWAITING = 'AWAITING',
  CANCELED = 'CANCELED',
  IN_DELIVERY = 'IN_DELIVERY',
  ANALYSIS = 'ANALYSIS',
  FINISHED = 'FINISHED',
}

export interface ISubmissionBasic {
  id?: string
  locationId: string
  requestName: string
  note?: string
  isPriority: boolean
  samplesAmount: number
  samples: ITempSample[]
  isAccepted: boolean
  isCanceled: boolean
  status: SUBMISSION_STATUS
}

export interface ISubmission extends ISubmissionBasic {
  createdAt?: string
  ownerId?: string
  owner?: {
    id: string
    name: string
    surname: string
    email: string
  }
  location?: {
    id: string
    name: string
    secondaryName: string
    city: string
    zipCode: string
    street: string
  }
}

export interface ISample {
  id?: string
  vialId: string
  name: string
  result?: string
  submissionId?: string
  note?: string
  timestamp?: date
  personName?: string,
  personSurname?: string,
  personIdentification?: string
}

export interface ITempSample extends ISample {
  isNew?: boolean
  isDirty?: boolean
  isDeleted?: boolean
}

export interface OptionProps {
  label: string
  value: string
}
