import * as A from './actionTypes'
import { AuthReducerState, IUser } from './types'

export const emptyUser: IUser = {
  id: '',
  email: '',
  name: '',
  surname: '',
  isActive: true,
  isAdmin: false,
  userLocation: []
}

export const initialState: AuthReducerState = {
  user: emptyUser,
  isLoggedIn: false,
  isLoading: false,
  loginErrorMessage: '',
}

const authReducer = (state = initialState, action: any): AuthReducerState => {
  switch (action.type) {
    case A.LOGIN_USER: {
      return {
        ...state,
        isLoading: true,
        loginErrorMessage: '',
      }
    }
    case A.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        user: { ...emptyUser, ...action.data.user },
      }
    }
    case A.LOGIN_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        loginErrorMessage: action.data,
      }
    }
    case A.LOGOUT_USER_SUCCESS: {
      return initialState
    }
    case A.UPDATE_USER: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case A.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.data,
      }
    }
    case A.UPDATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default authReducer
