import { ChangeEvent, useState } from 'react'
import { ITempSample } from '../../../utils/types'
import { useUserStatus } from '../../../redux/auth/selectors'
import { useSelector } from 'react-redux'
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as S from './Submission.styles'

interface ISamplesForm {
  isNew: boolean
  samples: ITempSample[]
  isInProgress: boolean
  note?: string
  handleAddSample: () => void
  handleSampleUpdate: (index: number, field: string, value: any) => void
}

export default function SamplesForm({
  isInProgress,
  isNew,
  samples,
  handleSampleUpdate,
  handleAddSample,
}: ISamplesForm) {
  const { isAdmin } = useSelector(useUserStatus)
  const isOwner = !isAdmin
  const isDisabled = isInProgress && isOwner ? true : isAdmin;
  const isNotesEnabled = isAdmin
  const [, handleDateChange] = useState(new Date());
  const updateDate = (newDate: Date) => handleDateChange(newDate)
  const updateSample = (index: number, field: string, newDate: Date) => handleSampleUpdate(index, 'timestamp', newDate)
  const updateSampleWP = (index: number, field: string, newDate: any,) => {
    updateDate(newDate)
    updateSample(index, 'timestamp', newDate)
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
        <>
          <S.SamplesHeader>Próbki:</S.SamplesHeader>
          <S.SamplesWrapper>
            <S.SampleWrapper>
              <S.Label maxWidth='50%' borderRight={isAdmin ? true : false}>Dane użytkownika</S.Label>
              {isAdmin &&  <S.Label maxWidth='50%' borderRight>Notatka</S.Label>}
              {isAdmin &&  <S.Label maxWidth='250px' borderRight>Czas</S.Label>}
              {isAdmin &&   <S.Label maxWidth='150px'>Wyniki</S.Label>}
            </S.SampleWrapper>
            {samples.map(({ note, vialId, result, isDeleted, timestamp, personName, personSurname, personIdentification }: ITempSample, index: number) => (
              <li key={`sample_${index}`}>
                <S.SampleWrapper isOpaque={isDeleted}>
                  {/* <S.RowLabel>{index + 1}.</S.RowLabel> */}
                  <S.UserRowContainer flexGrow={1} isAdmin={isAdmin} maxWidth={isAdmin ? '50%' : "100%"}>
                    <S.UserRowSimpleContainer>
                      <S.NewRowLabel>Id próbki</S.NewRowLabel>
                  <S.SampleInput
                    maxLength={8}
                    type={'text'}
                    placeholder='Id próbki'
                    required
                    style={{ flex:'1',minHeight: "56px" }}
                    value={vialId}
                    disabled={isDisabled}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleSampleUpdate(index, 'vialId', e.target.value)
                    }
                  />
                  </S.UserRowSimpleContainer>
                  <S.UserRowSimpleContainer>
                      <S.NewRowLabel>Pesel</S.NewRowLabel>
                  <S.SampleInput
                    type={'text'}
                    placeholder="Pesel"
                    style={{flex:'1', minHeight: "56px" }}
                    value={personIdentification}
                    disabled={isDisabled}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleSampleUpdate(index, 'personIdentification', e.target.value)
                    }
                  />
                                </S.UserRowSimpleContainer>
                                      <S.UserRowSimpleContainer>
                      <S.NewRowLabel>Imie</S.NewRowLabel>
                         <S.SampleInput
                    type={'text'}
                    placeholder='Imie'
                    style={{ flex:'1', minHeight: "56px" }}
                    value={personName}
                    disabled={isDisabled}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleSampleUpdate(index, 'personName', e.target.value)
                    }
                  />
                                </S.UserRowSimpleContainer>
                                      <S.UserRowSimpleContainer>
                      <S.NewRowLabel>Nazwisko</S.NewRowLabel>
                         <S.SampleInput
                    type={'text'}
                    placeholder='Nazwisko'
                    style={{ flex:'1', minHeight: "56px" }}
                    value={personSurname}
                    disabled={isDisabled}
                    onChange={(e: ChangeEvent<{ value: string }>) =>
                      handleSampleUpdate(index, 'personSurname', e.target.value)
                    }
                  />
                     </S.UserRowSimpleContainer>
                  </S.UserRowContainer>
                  {isAdmin && (
                  <S.RowContainer flexGrow={1} maxWidth='50%'>
            
                    <S.StyledTextArea
                      rows={5.5}
                      value={note || ""}
                      style={{ minHeight: "56px", paddingTop: "14px" }}
                      disabled={!isNotesEnabled}
                      onChange={(e: ChangeEvent<{ value: string }>) =>
                        handleSampleUpdate(index, 'note', e.target.value)
                      }
                    />
                  </S.RowContainer>
                  )}
                  {isAdmin && (

                  <S.RowContainer flexGrow={1} maxWidth='250px'>
                    <S.DatePicker
                      autoOk={true}
                      inputVariant="outlined"
                      format="dd MMMM yyyy HH:mm"
                      style={{ minWidth: "220px" }}
                      value={timestamp ? `${timestamp}` : null}
                      onChange={(newDate) => updateSampleWP(index, 'timestamp', newDate)}
                    />
                  </S.RowContainer>
                  )}
                  {isAdmin && (

                  <S.RowContainer flexGrow={1} maxWidth='150px'>
                    <S.SampleInput
                      maxLength={4}
                      style={{ minHeight: "56px" }}
                      disabled={isOwner}
                      type={'text'}
                      value={result}
                      onChange={(e: ChangeEvent<{ value: string }>) =>
                        handleSampleUpdate(index, 'result', e.target.value)
                      }
                    />
                  {isAdmin && (
                    <span>mg/L</span>
                  )}
                  </S.RowContainer>
                  )}

                  {!isNew && (
                    <S.DeleteButtonContainer isAdmin={isAdmin}>
                      <S.DeleteSampleButton
                        isRed={true}
                        onClick={() => handleSampleUpdate(index, 'isDeleted', !isDeleted)}
                        disabled={isDisabled}
                        type={'button'}
                      >
                        <S.CloseIcon />
                      </S.DeleteSampleButton>
                    </S.DeleteButtonContainer>
                  )}
                </S.SampleWrapper>
              </li>
            ))}
          </S.SamplesWrapper>
          {(isOwner && (
            <S.DeleteSampleButton
              hasPadding
              isRed={true}
              onClick={handleAddSample}
              disabled={isInProgress}
              type={'button'}
            >
              Dodaj próbkę
            </S.DeleteSampleButton>
          ))}
        </>
      </MuiPickersUtilsProvider>
    </>
  )
}
