import tdmSchema from '../../public/assets/tdmSchema.svg'
import tdmComplex from '../../public/assets/tdmComplex.svg'
import PictureAndListSection from '../PictureAndListSection/PictureAndListSection'
import * as S from './MonitoringTDM.styles'

const MonitoringTDM = () => (
  <S.Wrapper>
    <PictureAndListSection
      alt={'drug concentration and efficacy relation schema'}
      image={tdmSchema}
      text={`Pomiędzy stężeniem leku we krwi a\u00A0jego działaniem istnieje większa korelacja niż pomiędzy podaną dawką leku a działaniem. TDM [Therapeutic Drug Monitoring] ma na celu takie dawkowanie leku u\u00A0chorego, aby uzyskane stężenia mieściły się w\u00A0zakresie przedziału stężeń charakteryzujących się dla danego leku dużym stopniem skuteczności działania i\u00A0małym ryzykiem wystąpienia objawów toksycznych.`}
    />
    <S.DiagramWrapper>
      <img src={tdmComplex} alt="tdm complex diagram" />
    </S.DiagramWrapper>
  </S.Wrapper>
)

export default MonitoringTDM
