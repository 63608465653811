import { combineReducers, createStore, applyMiddleware } from 'redux'
// import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import AuthReducer from './auth/authReducer'
import DataReducer from './data/dataReducer'

import { AuthReducerState } from './auth/types'
import { DataReducerState } from './data/types'

const rootReducer = combineReducers({
  auth: AuthReducer,
  data: DataReducer,
})

export interface AppState {
  auth: AuthReducerState
  data: DataReducerState
}

const store = (preloadedState: AppState) =>
  createStore(rootReducer, preloadedState, applyMiddleware(thunkMiddleware))

export default store
